/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Link, Typography, Grid } from '@material-ui/core';
import { capitaliseWord } from '../utils/capitaliseWord';
import { getEventBookingNotificationForm, getEventSocialMedia } from '../utils/locationSpecificInfo';

const styles = (theme) => ({
  signUpText: {
    textAlign: 'justify',
    marginBottom: '1em',
  },
  newWindowLink: {
    textAlign: 'right',
    margin: '2em 0 0.5em',
  },
  link: {
    fontWeight: 700,
  },
  soldOut: {
    marginTop: '1em',
    textAlign: 'center',
  },
  important: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
});

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      location: routes[1],
    };
    this.registerOpenedInNewTab = this.registerOpenedInNewTab.bind(this);
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Sign Up`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/sign-up` });
  }

  registerOpenedInNewTab() {
    ReactGA.event({
      category: 'Forms',
      action: 'Open in new tab',
      label: `Sign Up - ${capitaliseWord(this.state.location)}`,
    });
  }

  registerClickToSocialMedia(type) {
    ReactGA.event({
      category: 'Social Media',
      action: 'View profile',
      label: type,
    });
  }

  render() {
    const { classes } = this.props;
    const socialMediaLinks = getEventSocialMedia(this.state.location);
    // const signUpForm = getEventSignUpForm(this.state.location);
    const bookingNotificationForm = getEventBookingNotificationForm(this.state.location);
    if (this.state.location === 'avon') {
      return (
        <div>
          <Typography variant="h4">Sign Up</Typography>
          <Typography variant='h5' component='p' style={{ marginTop: '2em', textAlign: 'center', fontWeight: 700 }}>
            The Be Prepared Avon 2023 event has now finished
          </Typography>
          <Typography variant='h6' component='p' style={{ textAlign: 'center' }}>
            Sign up below, or follow us on <Link href={socialMediaLinks.facebook} target='_blank' rel="noopener noreferrer" className={classes.link} onClick={() => this.registerClickToSocialMedia(`Be Prepared ${capitaliseWord(this.state.location)} Facebook`)}>Facebook</Link> and <Link href={socialMediaLinks.instagram} target='_blank' rel="noopener noreferrer" className={classes.link} onClick={() => this.registerClickToSocialMedia(`${capitaliseWord(this.state.location)} Instagram`)}>Instagram</Link> to be the first to know details of the next event!
          </Typography>
          <Grid container justify="center">
            <Grid item xs={12} sm={8} md={6}>
              <Typography className={classes.newWindowLink}><Link href={bookingNotificationForm.shortLink} target='_blank' rel="noopener noreferrer" className={classes.link} onClick={this.registerOpenedInNewTab}>Open form in new window</Link></Typography>
              <iframe src={bookingNotificationForm.longLink} width="100%" height="500" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
            </Grid>
          </Grid>
        </div>
      );
    }
    return (
      <div>
        <Typography variant="h4">Sign Up</Typography>
        <Typography variant='h5' component='p' style={{ marginTop: '2em', textAlign: 'center', fontWeight: 700 }}>
          The Be Prepared London 2023 event has now finished
        </Typography>
        <Typography variant='h6' component='p' style={{ textAlign: 'center' }}>
            Sign up below, or follow us on <Link href={socialMediaLinks.facebook} target='_blank' rel="noopener noreferrer" className={classes.link} onClick={() => this.registerClickToSocialMedia(`Be Prepared ${capitaliseWord(this.state.location)} Facebook`)}>Facebook</Link> and <Link href={socialMediaLinks.instagram} target='_blank' rel="noopener noreferrer" className={classes.link} onClick={() => this.registerClickToSocialMedia(`${capitaliseWord(this.state.location)} Instagram`)}>Instagram</Link> to be the first to know details of the next event!
        </Typography>
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6}>
            <Typography className={classes.newWindowLink}><Link href={bookingNotificationForm.shortLink} target='_blank' rel="noopener noreferrer" className={classes.link} onClick={this.registerOpenedInNewTab}>Open form in new window</Link></Typography>
            <iframe src={bookingNotificationForm.longLink} width="100%" height="500" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(SignUp);
