/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardHeader, Link, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { getUnitsAndNumberOfTimes } from '../utils/units';
import { getImageData } from '../utils/past-events';
import PastEventPhotos from '../components/PastEventPhotos';

const styles = (theme) => ({
  title: {
    paddingBottom: '0.5em',
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '1em',
    flexWrap: 'wrap',
  },
  card: {
    width: 'min(95vw, 500px)',
    overflow: 'visible',
    maxHeight: '800px',
  },
  scrollableCard: {
    width: 'min(95vw, 500px)',
    [theme.breakpoints.up('md')]: {
      maxHeight: '800px',
    },
  },
  cardContent: {
    textAlign: 'center',
    paddingTop: 0,
    overflowY: 'visible',
  },
  scrollableCardContent: {
    textAlign: 'center',
    paddingTop: 0,
    overflow: 'scroll',
    [theme.breakpoints.up('md')]: {
      columns: 2,
    },
  },
  headerTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.5em',
  },
  subheaderTitle: {
    textAlign: 'center',
  },
  list: {
    padding: 0,
  },
  listItem: {
    textAlign: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    breakInside: 'avoid',
  },
  eventSummary: {
    marginTop: '0.5em',
    [theme.breakpoints.up('lg')]: {
      height: '75px',
    },
  },
  link: {
    fontWeight: 700,
    wordBreak: 'break-all',
  },
  boldColoured: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
});

const cardDetails = [
  {
    title: 'Be Prepared Avon 2022',
    date: 'October 15th and 16th 2022',
    location: 'Cleeve Hill Scout Campsite',
    summary: 'We got very lucky with the weather and all participants had a great time - some very close scores on the Guide Day.',
    googleDriveLink: 'https://photos.app.goo.gl/pBRgVwsp7ALpx9i78',
    photos: getImageData('avon', 2022),
    scores: 'TBC',
  }, {
    title: 'Be Prepared London 2022',
    date: 'April 2nd and 3rd 2022',
    location: 'Wilberforce Scout Centre',
    summary: 'Although there were a few snow showers as we were setting up, the rest of the weekend was gloriously sunny, and all girls had a brilliant time.',
    googleDriveLink: 'https://photos.app.goo.gl/PnMKcEFx7VcjhFSi8',
    photos: getImageData('london', 2022),
    scores: 'TBC',
  },
];

class PastEvents extends React.Component {
  componentDidMount() {
    document.title = 'Be Prepared Past Events';
    ReactGA.send({ hitType: 'pageview', page: 'past-events' });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h4" className={classes.title}>Past Events</Typography>
        <div className={classes.cardContainer}>
          <Card raised className={classes.scrollableCard}>
            <CardHeader
              classes={{ title: classes.headerTitle, subheader: classes.subheaderTitle }}
              title="Previous Units"
              subheader="Units that have attended any Be Prepared Event"
            />
            <CardContent className={classes.scrollableCardContent}>
              <List dense className={classes.list}>
                {getUnitsAndNumberOfTimes().map(unit => (
                  <ListItem key={unit} className={classes.listItem}>
                    <ListItemText primary={unit} primaryTypographyProps={{ variant: 'subtitle1' }} />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
          {cardDetails.map(card => (
            <Card key={card.title} raised className={classes.card}>
              <CardHeader
                classes={{ title: classes.headerTitle }}
                title={card.title}
              />
              <CardContent className={classes.cardContent}>
                <Typography><span className={classes.boldColoured}>Date:</span> {card.date}</Typography>
                <Typography><span className={classes.boldColoured}>Location:</span> {card.location}</Typography>
                <Typography className={classes.eventSummary}>{card.summary}</Typography>
                <PastEventPhotos photos={card.photos} googleDriveLink={card.googleDriveLink} />
                <div>
                  <Typography>More photos from the event are available at:</Typography>
                  <Typography><Link className={classes.link} href={card.googleDriveLink} target='_blank' rel="noopener noreferrer">{card.googleDriveLink}</Link></Typography>
                </div>
              </CardContent>
            </Card>
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(PastEvents);
