/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import CustomLink from '../components/CustomLink';
import GuideLogo from '../images/logos/sections/guide-logo.jpg';
import ScoutLogo from '../images/logos/sections/scout-logo.png';
import { capitaliseWord } from '../utils/capitaliseWord';

const styles = () => ({
  paddingTopBottom: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  surroundingBox: {
    textAlign: 'center',
    borderRadius: '10px',
    padding: '10px',
    height: '100%',
  },
  guideColorBorder: {
    border: '3px solid #173a86',
  },
  scoutColorBorder: {
    border: '3px solid #7413dc',
  },
  imgBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '130px',
  },
  scoutImg: {
    height: '100px',
    margin: '1em',
  },
  guideImg: {
    height: '80px',
    margin: '1em',
  },
  underline: {
    textDecoration: 'underline',
  },
  bold: {
    fontWeight: 700,
  },
  smallMarginAbove: {
    marginTop: '0.5em',
  },
  biggerMarginTop: {
    marginTop: '1em',
  },
  marginBottom: {
    marginBottom: '1em',
  },
});

class RelatedBadges extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      location: routes[1],
    };
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Badges`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/badges` });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h4" component='h1'>Related Badges</Typography>
        <Typography>Full participation in Be Prepared may tick off the following badge clauses (dependant on which bases are run).</Typography>
        <Typography>If you think we have missed a badge clause from this list, please <CustomLink linkText='get in touch' linkLocation={`/${this.state.location}/contact`} /> and give a brief description of the badge clause.</Typography>
        <Grid container justify="center" className={classes.paddingTopBottom} spacing={2}>
          <Grid item xs={12} sm={8} md={4}>
            <div className={`${classes.surroundingBox} ${classes.scoutColorBorder}`}>
              <div className={classes.imgBox}>
                <img src={ScoutLogo} alt='Scouts' className={classes.scoutImg} />
              </div>
              <Typography variant='h5' component='h3' className={`${classes.bold}`}>Emergency Aid Staged Activity Badge</Typography>
              <Typography variant='h6' component='h4' className={`${classes.underline} ${classes.smallMarginTop}`}>Stage 1</Typography>
              <Typography>Clause 1, parts a, c, d, e</Typography>
              <Typography variant='h6' component='h4' className={`${classes.underline} ${classes.smallMarginTop}`}>Stage 2</Typography>
              <Typography>Clause 1, parts a, c</Typography>
              <Typography>Clause 2, parts a, b, c</Typography>
              <Typography variant='h6' component='h4' className={`${classes.underline} ${classes.smallMarginTop}`}>Stage 3</Typography>
              <Typography>Clause 2, parts a, b, c, d</Typography>
              <Typography variant='h5' component='h3' className={`${classes.bold} ${classes.biggerMarginTop}`}>Communicator Activity Badge</Typography>
              <Typography>Option 2, part a</Typography>
              <Typography variant='h5' component='h3' className={`${classes.bold} ${classes.biggerMarginTop}`}>Lifesaver Activity Badge</Typography>
              <Typography>Clause 1, part 4 (throwing a rope)</Typography>
              <Typography variant='h5' component='h3' className={`${classes.bold} ${classes.biggerMarginTop}`}>Global Issues Activity Badge</Typography>
              <Typography>Clause 2</Typography>

            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={4}>
            <div className={`${classes.surroundingBox} ${classes.guideColorBorder}`}>
              <div className={classes.imgBox}>
                <img src={GuideLogo} alt='Guides' className={classes.guideImg} />
              </div>
              <Typography variant='h5' component='h3' className={`${classes.bold}`}>First Aid Skills Builder</Typography>
              <Typography variant='h6' component='h4' className={`${classes.underline} ${classes.smallMarginTop}`}>Stage 2</Typography>
              <Typography>Call for a DR ABC</Typography>
              <Typography>Lie Back and Recover</Typography>
              <Typography variant='h6' component='h4' className={`${classes.underline} ${classes.smallMarginTop}`}>Stage 3</Typography>
              <Typography>Blazing Burns</Typography>
              <Typography variant='h5' component='h3' className={`${classes.bold} ${classes.biggerMarginTop}`}>UMAs</Typography>
              <Typography className={classes.marginBottom}>Thirsty Work (Have Adventures, 20 minutes)</Typography>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(RelatedBadges);
