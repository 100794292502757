const years = [
  {
    year: '2022',
    location: 'london',
    units: [
      '2nd Crofton Guides',
      '2nd Hurst Guides',
      '3rd Beckenham Guides',
      '3rd Farnborough Guides',
      '3rd Meopham Guides',
      '3rd Sidcup Guides',
      '4th Sidcup Guides',
      '9th Orpington Guides',
      '18th Bromley Guides',
      'Goddington Guides',
      'Islington Division',
    ],
  }, {
    year: '2022',
    location: 'avon',
    units: [
      '1st Bristol Muslim Scouts',
      '1st Locking Castle Guides',
      '14th Clevedon Guides',
      '18th Bath Guides',
      '21st Bath (Larkhall) Scouts',
      '24th Bath Rush Hill (URC) Guides',
      '28th Bathampton Scout Troop',
      '31st Bath Guides',
      '51st Bath Scouts',
      'Brent Knoll Guides',
      'Highbridge Guides',
    ],
  },
];

const sortUnitsAlphabetically = (a, b) => {
  const aStartsWithNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(a[0]);
  const bStartsWithNumber = ['1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(b[0]);

  if (aStartsWithNumber && !bStartsWithNumber) {
    // numerical comes before alphabetical
    return -1;
  }
  if (!aStartsWithNumber && bStartsWithNumber) {
    // numerical comes before alphabetical
    return 1;
  }
  if (!aStartsWithNumber && !bStartsWithNumber) {
    // if neither are numerical, then sort alphabetically
    return a > b ? 1 : -1;
  }
  if (aStartsWithNumber && bStartsWithNumber) {
    // if both are numerical, then can use parseInt to take the numbers from the start and sort by those
    // (standard alphabetical would put 14th, 1st, 21st, 2nd etc)
    if (parseInt(a) === parseInt(b)) {
      // if they have the same unit number, then sort by standard alphabetical rules
      return a > b ? 1 : -1;
    }
    return parseInt(a) > parseInt(b) ? 1 : -1;
  }
};

// returns units for all years, sorted alphabetically
// TODO sort by number of events they have come to, then alphabetical?
export const getUnitsAndNumberOfTimes = () => {
  const allUnits = [...years[0].units, ...years[1].units];
  allUnits.sort(sortUnitsAlphabetically);
  return allUnits;
};
