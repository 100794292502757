import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Button, Link, TextField, Typography } from '@material-ui/core';
import CustomLink from '../components/CustomLink';
import { capitaliseWord } from '../utils/capitaliseWord';
import { getContactFormLink, getEmailAddress } from '../utils/locationSpecificInfo';

const styles = (theme) => ({
  subtitle: {
    marginBottom: '1em',
  },
  link: {
    fontWeight: 700,
  },
  form: {
    maxWidth: '750px',
    display: 'block',
    padding: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
  },
  formTitle: {
    textAlign: 'center',
  },
  errorHelperText: {
    color: theme.palette.error.main,
  },
  messageBox: {
    marginTop: '30px',
    marginBottom: '20px',
  },
  submitButton: {
    display: 'inline-block',
    float: 'right',
  },
  responseText: {
    textAlign: 'center',
    marginTop: '20px',
  },
  helperText: {
    textAlign: 'right',
  },
  clearFloats: {
    clear: 'both',
  },
  notCompleteWarning: {
    textAlign: 'center',
  },
});

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      location: routes[1],
      status: '',
      name: '',
      email: '',
      repeatEmail: '',
      message: '',
      nameError: false,
      emailError: false,
      repeatEmailError: false,
      messageError: false,
      maxMessageLength: 1000,
    };
    this.submitForm = this.submitForm.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleNameBlur = this.handleNameBlur.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleEmailBlur = this.handleEmailBlur.bind(this);
    this.handleRepeatEmailChange = this.handleRepeatEmailChange.bind(this);
    this.handleRepeatEmailBlur = this.handleRepeatEmailBlur.bind(this);
    this.handleMessageChange = this.handleMessageChange.bind(this);
    this.handleMessageBlur = this.handleMessageBlur.bind(this);
    this.onClickCancel = this.onClickCancel.bind(this);
    this.allFieldsValid = this.allFieldsValid.bind(this);
    this.checkFields = this.checkFields.bind(this);
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Contact`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/contact` });
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    if (!this.allFieldsValid()) {
      return;
    }
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({
          status: 'SUCCESS',
          name: '',
          email: '',
          repeatEmail: '',
          message: '',
          nameError: false,
          emailError: false,
          repeatEmailError: false,
          messageError: false,
        });
        ReactGA.event({
          category: 'Contact Us',
          action: 'Submit form',
          label: `Submit ${this.state.location} contact us form`,
        });
      } else {
        this.setState({ status: 'ERROR' });
      }
    };
    xhr.send(data);
  }

  handleNameChange(event) {
    this.setState({
      name: event.target.value,
      nameError: false,
      status: '',
    });
  }

  handleNameBlur() {
    this.setState({
      nameError: this.state.name.length === 0,
      status: '',
    });
  }

  handleEmailChange(event) {
    this.setState({
      email: event.target.value,
      emailError: false,
      status: '',
    });
  }

  handleEmailBlur() {
    this.setState({
      emailError: !emailRegex.test(String(this.state.email).toLowerCase()),
      status: '',
    });
  }

  handleRepeatEmailChange(event) {
    this.setState({
      repeatEmail: event.target.value,
      repeatEmailError: false,
      status: '',
    });
  }

  handleRepeatEmailBlur() {
    const { email, repeatEmail } = this.state;

    this.setState({
      repeatEmailError: email !== repeatEmail,
    });
  }

  handleMessageChange(event) {
    const maxMessageLength = this.state.maxMessageLength;
    const message = event.target.value;
    this.setState({
      message: message,
      messageError: message.length > maxMessageLength,
      status: '',
    });
  }

  handleMessageBlur() {
    const maxMessageLength = this.state.maxMessageLength;
    const { message } = this.state;
    this.setState({
      messageError: message.length > maxMessageLength || message.length === 0,
      status: '',
    });
  }

  onClickCancel() {
    this.setState({
      status: '',
      name: '',
      email: '',
      repeatEmail: '',
      message: '',
      nameError: false,
      emailError: false,
      repeatEmailError: false,
      messageError: false,
    });
  }

  allFieldsValid() {
    return !(this.state.nameError || this.state.emailError || this.state.repeatEmailError || this.state.messageError);
  }

  checkFields() {
    const { name, email, repeatEmail, message, maxMessageLength } = this.state;

    this.setState({
      nameError: name.length === 0,
      emailError: !emailRegex.test(String(email).toLowerCase()),
      repeatEmailError: email !== repeatEmail,
      messageError: message.length > maxMessageLength || message.length === 0,
    });
  }

  render() {
    const { classes } = this.props;
    const emailAddress = getEmailAddress(this.state.location);

    return (
      <div>
        <div>
          <Typography variant="h4" component='h1'>Contact Us</Typography>
          {/* eslint-disable-next-line max-len */}
          <Typography className={classes.subtitle}>If your question has not been answered by the <CustomLink linkText='FAQ page' linkLocation={`/${this.state.location}/faq`} /> then fill in the form below or email us at <Link href={`mailto:${emailAddress}`} className={classes.link}>{emailAddress}</Link>.</Typography>
        </div>

        <form
          onSubmit={this.submitForm}
          action={getContactFormLink(this.state.location)}
          method="POST"
          className={classes.form}
        >
          <Typography variant='h5' component='h2' className={classes.formTitle}>Contact Be Prepared {capitaliseWord(this.state.location)}</Typography>
          <TextField
            id="name"
            label="Name"
            color='primary'
            required
            fullWidth
            name="name"
            error={this.state.nameError}
            value={this.state.name}
            onChange={this.handleNameChange}
            onBlur={this.handleNameBlur}
            helperText={this.state.nameError ? 'Please enter a name' : ' '}
          />

          <TextField
            id="email-address"
            label="Email Address"
            color='primary'
            required
            fullWidth
            name="email"
            error={this.state.emailError}
            value={this.state.email}
            onChange={this.handleEmailChange}
            onBlur={this.handleEmailBlur}
            helperText={this.state.emailError ? 'Invalid email address' : ' '}
          />

          <TextField
            id="repeat-email-address"
            label="Repeat Email Address"
            color='primary'
            required
            fullWidth
            // name="email"
            error={this.state.repeatEmailError}
            value={this.state.repeatEmail}
            onChange={this.handleRepeatEmailChange}
            onBlur={this.handleRepeatEmailBlur}
            helperText={this.state.repeatEmailError ? 'Email addresses don\'t match' : ' '}
          />

          <TextField
            id="message"
            label="Message"
            color='primary'
            required
            multiline
            fullWidth
            variant='outlined'
            name="message"
            rows={6}
            className={classes.messageBox}
            placeholder='Please type your message here'
            error={this.state.messageError}
            value={this.state.message}
            onChange={this.handleMessageChange}
            onBlur={this.handleMessageBlur}
            FormHelperTextProps={{ className: classes.helperText }}
            helperText={`Character Count: ${this.state.message.length}/${this.state.maxMessageLength}`}
          />

          {!this.allFieldsValid() && <Typography color='error' className={classes.notCompleteWarning}>Please provide all the required information</Typography>}

          <Button
            variant='contained'
            color='primary'
            type='submit'
            className={classes.submitButton}
            disabled={!this.allFieldsValid()}
            onClick={() => this.checkFields()}
          >
            Submit
          </Button>
          <Button
            className={classes.submitButton}
            onClick={this.onClickCancel}
          >
            Cancel
          </Button>

          <div className={classes.clearFloats} />

          {this.state.status === 'SUCCESS' && <Typography className={classes.responseText}>Thanks! Your question has been sent to us and we will reply as soon as possible.</Typography>}
          {this.state.status === 'ERROR' && <Typography color='error' className={classes.responseText}>Sorry, something went wrong. Please try again in a few minutes.</Typography>}
        </form>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(ContactUs));
