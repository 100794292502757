import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import './index.css';
import App from './App';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';

ReactGA.initialize('G-B7KY5H5DRE');

const theme = createMuiTheme({
  overrides: {
    MuiLink: {
      root: {
        cursor: 'pointer',
        '&:focus-visible': {
          outline: '3px solid #4d2177',
        },
      },
    },
  },
  props: {
    MuiLink: {
      tabIndex: 0,
      underline: 'hover',
    },
  },
  palette: {
    primary: {
      main: '#4d2177',
    },
    secondary: {
      main: '#c3c3c3',
    },
  },
  typography: {
    fontFamily: '"Open Sans", Helvetica, Arial, sans-serif',
    button: {
      textTransform: 'none',
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollToTop>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </ScrollToTop>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);
