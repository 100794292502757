import React from 'react';
import ReactGA from 'react-ga4';
import { Typography } from '@material-ui/core';
import CustomLink from '../components/CustomLink';

class ErrorRoute extends React.Component {
  componentDidMount() {
    // send whatever page the user landed on
    document.title = 'Be Prepared Resilience Event';
    ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname });
  }

  render() {
    return (
      <div style={{ width: '100%', textAlign: 'center', marginTop: '100px' }}>
        <Typography variant='h4' color='primary' style={{ marginBottom: '1em' }}>Oops, looks like the link you tried to access doesn&apos;t exist.</Typography>
        <Typography>If you clicked a link on our website and ended up here, please <CustomLink linkText='let us know' linkLocation='/london/contact' />.</Typography>
        <Typography>In the meantime, please try again, or <CustomLink linkText='go back to the homepage' linkLocation='/' />.</Typography>
      </div>
    );
  }
}

export default ErrorRoute;
