import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { capitaliseWord } from '../../utils/capitaliseWord';
import { CssBaseline, AppBar, Toolbar, Typography, Link, Hidden } from '@material-ui/core';
import SecondAppBarLinks from './SecondAppBarLinks';
import MobileMenu from '../MobileMenu';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    paddingBottom: '0px',
  },
  title: {
    textAlign: 'left',
    whiteSpace: 'nowrap',
    marginBottom: '7px',
    marginRight: '50px',
    [theme.breakpoints.down('md')]: {
      marginRight: 0, // stops the content overflowing on small screens
    },
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  link: {
    '&:focus-visible': {
      outline: '3px solid white',
    },
  },
});

class SecondAppBar extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      currentPath,
      location: routes[1],
    };
    this.backToHome = this.backToHome.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  componentDidUpdate() {
    const newPath = this.props.location.pathname;
    const routes = newPath.split('/');
    if (newPath !== this.state.currentPath) {
      this.setState({
        currentPath: newPath,
        location: routes[1],
      });
    }
  }

  backToHome() {
    this.props.history.push(`/${this.state.location}`);
  }

  keyDown(e) {
    if (e.charCode === 13) {
      this.backToHome();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
        <CssBaseline/>
        <AppBar position="sticky" className={classes.appBar}>
          <Toolbar>
            <Hidden lgUp>
              <MobileMenu />
            </Hidden>
            <Typography
              variant="h5"
              className={classes.title}
              style={{ borderBottom: this.props.location.pathname === `/${this.state.location}` ? '3px solid #c3c3c3' : '3px solid #4d2177' }}
            >
              <Link
                onClick={this.backToHome}
                onKeyPress={this.keyDown}
                color='inherit'
                underline='none'
                className={classes.link}
              >
                Be Prepared {capitaliseWord(this.state.location)}
              </Link>
            </Typography>
            <Hidden mdDown>
              <SecondAppBarLinks />
            </Hidden>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(SecondAppBar));
