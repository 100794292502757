/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Divider, Grid, Link, Typography } from '@material-ui/core';
import CustomLink from '../components/CustomLink';
import DanielPhoto from '../images/team/Daniel.jpg';
import LizzyPhoto from '../images/team/Lizzy.jpeg';
import HannahPhoto from '../images/team/Hannah.jpg';
import AlexPhoto from '../images/team/Alex.jpg';
import { getVolunteersForYear, getYears } from '../utils/volunteers';
import { capitaliseWord } from '../utils/capitaliseWord';
import { getVolunteersFacebookGroup } from '../utils/locationSpecificInfo';

const styles = (theme) => ({
  grid: {
    maxWidth: '100vw', // stops overflow issues
  },
  teamProfile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  teamName: {
    margin: '0.5em 0',
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  paragraph: {
    marginBottom: '0.5em',
    textAlign: 'justify',
  },
  favouriteBaseWrapper: {
    textAlign: 'center',
  },
  favouriteBase: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  link: {
    fontWeight: 700,
    wordBreak: 'break-all', // link text is really long with no spaces and so caused overflow problems on mobile
  },
  marginBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  smallMarginTop: {
    marginTop: '0.5em',
  },
  textCenter: {
    textAlign: 'center',
  },
  textJustify: {
    textAlign: 'justify',
  },
  divider: {
    margin: '2em 0',
    backgroundColor: theme.palette.primary.main,
  },
  volunteersList: {
    marginLeft: theme.spacing(3),
  },
});

class Team extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      location: routes[1],
    };
    this.registerFacebookLinkClicked = this.registerFacebookLinkClicked.bind(this);
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Team`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/team` });
  }

  registerFacebookLinkClicked() {
    ReactGA.event({
      category: 'Social Media',
      action: 'View group',
      label: `${capitaliseWord(this.state.location)} Volunteers Facebook Group`,
    });
  }

  render() {
    const { classes } = this.props;
    const volunteersFacebookGroup = getVolunteersFacebookGroup(this.state.location);
    return (
      <div>
        <Typography variant="h4" component='h1'>The Team</Typography>
        <Typography variant='h5' component='h2' className={classes.marginTop}>Core Team</Typography>
        <Typography className={classes.marginBottom}>Our core team are responsible for the running of Be Prepared. You will find us around site on the day wearing green hi-vis neckers.</Typography>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item sm={12} md={6}>
            <div className={classes.teamProfile}>
              <Typography variant='h6' component='body' className={classes.teamName}>Daniel Morland</Typography>
              <img src={DanielPhoto} alt='Photo of Daniel' height='100px'/>
            </div>
            <Typography className={classes.paragraph}>I started my Scouting journey way back in 2000 as a Beaver at Crofton Oak.  I moved up through the Scouting movements (learning lots I might add) until I became a member of Drymen Explorers, and also started helping as a Young Leader at Crofton Oak.  When I grew up (sorry, older!) I became a full Adult Leader with Crofton Oak Beavers, before going off to uni where I joined Bath University Guides and Scouts (BUGS) which is part of SSAGO.  Here I met many wonderful people and got introduced to the idea of Be Prepared.  After graduating I led a Beaver Troop in Bath whilst working there until I returned home to Orpington and become an Explorer Scout Leader for Crofton Oak.</Typography>
            <Typography className={classes.paragraph}>When I’m not Scouting, I’m at work as a Health and Safety Advisor on the railway for the construction industry.  Skills put to good use in ensuring our Scout events run smoothly and safely.</Typography>
            <Typography className={classes.paragraph}>You’ll find me running things over the weekend as part of the core leadership team in a green hi-vis necker so any problems, please let me know, or just stop by and say hi, if I’ve found a minute for a sit down!</Typography>
            <Typography className={classes.favouriteBaseWrapper}><span className={classes.favouriteBase}>Favourite Be Prepared Activity:</span> Casualties</Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.teamProfile}>
              <Typography variant='h6' component='body' className={classes.teamName}>Lizzy Morland</Typography>
              <img src={LizzyPhoto} alt='Photo of Lizzy' height='100px'/>
            </div>
            <Typography className={classes.paragraph}>I started my Guiding journey in 2000 as a Rainbow in Cheltenham. I then became a Brownie at Mum’s Brownie unit and then went onto Guides, getting my Baden Powell Award. I never really left Brownies though, staying on as a Young Leader and then becoming a full Adult Leader. I went to Bath University, also joining BUGS, and helping out at local Rainbow and Brownie units.  After meeting and marrying Daniel, I moved to Orpington and took over 5th Crofton Brownies in October 2019.</Typography>
            <Typography className={classes.paragraph}>Outside of Guiding I am a Software Engineer and I’ve put these skills to good use designing the website you’re on right now!</Typography>
            <Typography className={classes.paragraph}>I do the majority of the Be Prepared admin, and over the weekend you’ll find me reining in my husband and co-ordinating the leadership team to ensure you all have a safe and enjoyable time.</Typography>
            <Typography className={classes.favouriteBaseWrapper}><span className={classes.favouriteBase}>Favourite Be Prepared Activity:</span> Water</Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.teamProfile}>
              <Typography variant='h6' component='body' className={classes.teamName}>Hannah Morland</Typography>
              <img src={HannahPhoto} alt='Photo of Hannah' height='100px'/>
            </div>
            <Typography className={classes.paragraph}>I started my Guiding journey a little later than the other two in 2004 at 2nd Crofton Brownies.  I became a Guide and then briefly joined the dark side with my brother, Daniel, at Drymen Explorers (yes this event is a family affair!). I went to Keele university where I was on the committee for our SSAGO group, taking them from a handful of members to running an international camp in Kandersteg.  I came back home and am now a Leader with 2nd Crofton Brownies.</Typography>
            <Typography className={classes.paragraph}>Outside of Guiding I am a Maths teacher at a local secondary school so if people start misbehaving I’ll bring out my stern teacher voice!</Typography>
            <Typography className={classes.paragraph}>Over the weekend I’ll be running round sorting all the behind the scenes work and possibly helping on a base or two.</Typography>
            <Typography className={classes.favouriteBaseWrapper}><span className={classes.favouriteBase}>Favourite Be Prepared Activity:</span> Shelter</Typography>
          </Grid>
          <Grid item sm={12} md={6}>
            <div className={classes.teamProfile}>
              <Typography variant='h6' component='body' className={classes.teamName}>Alex Shire</Typography>
              <img src={AlexPhoto} alt='Photo of Alex' height='100px'/>
            </div>
            <Typography className={classes.paragraph}>I am NOT a Scout, or a Guide.  Well technically maybe I am but I try not to be…  I help out with Boys Brigade and was part of the Service Crew at Cleeve Hill campsite in Bath, where Be Prepared Avon is held, for many years.  I lend a hand at district and unit events where needed, as I can always seem to fix things that others have broken.</Typography>
            <Typography className={classes.paragraph}>Outside of this I am a mechanic, having worked on HIABS (large cranes) and lorries, hence why everyone comes to me to fix things.  </Typography>
            <Typography className={classes.paragraph}>Over the weekend, I won’t be running an activity - instead I’ll be the spare set of hands fixing things, moving equipment, and generally there to lend a hand.  If you need me, look for the flashing truck and I won’t be too far behind!</Typography>
            <Typography className={classes.favouriteBaseWrapper}><span className={classes.favouriteBase}>Favourite Be Prepared Activity:</span> one that doesn’t have parts that break! </Typography>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <Typography variant='h5' component='h2' className={classes.marginTop}>Volunteers</Typography>
        <Typography className={`${classes.marginBottom} ${classes.textJustify}`}>We couldn&apos;t run Be Prepared without our team of volunteers who run the bases, and help out around the site on the day. You will find them around site on the day wearing purple hi-vis neckers.</Typography>
        <Typography className={classes.marginBottom}>Our thanks go to the following people:</Typography>
        {getYears(this.state.location).map(year => {
          const volunteersList = getVolunteersForYear(this.state.location, year);
          return (
            <Typography key={year} className={classes.volunteersList}>
              {`${year}: ${volunteersList.length === 0 ? 'No one yet..!' : volunteersList.join(', ')}`}
            </Typography>
          );
        })}
        <Typography className={`${classes.marginTop} ${classes.textCenter}`}>If you are interested in joining our team of volunteers, please fill out the <CustomLink linkText='volunteers sign up form' linkLocation={`/${this.state.location}/volunteer`} /> and <Link href={volunteersFacebookGroup} target='_blank' rel="noopener noreferrer" className={classes.link} onClick={this.registerFacebookLinkClicked}>join our Facebook group</Link>.</Typography>

        <Divider className={classes.divider} />

        <Typography variant='h5' component='h2' className={classes.marginTop}>Additional Thanks</Typography>
        <Typography variant='h6' component='body' className={classes.smallMarginTop}>Amy Spencer</Typography>
        <Typography>Amy founded Be Prepared in 2010 in Bath and ran the event up until 2019. We are grateful that she agreed to us taking over the event and moving it across the country.</Typography>
        <Typography variant='h6' component='body' className={classes.smallMarginTop}>Girlguiding Greater London Kent County</Typography>
        <Typography>Thanks to GLK for the loan of their Resussi Annies, banners, and flags for the London 2022 Event, as well as their help in setting up a new event in the County.</Typography>
        <Typography variant='h6' component='body' className={classes.smallMarginTop}>Crofton Oak Scout Troop</Typography>
        <Typography>Many thanks to Crofton Oak Scouts for the loan of their trailer, and projector, as well as several smaller pieces of kit, for the London 2022 Event. Thanks also to the Explorer Scouts for helping us to sort out the suitcase activity during one of their meetings before the London 2022 Event.</Typography>
        <Typography variant='h6' component='body' className={classes.smallMarginTop}>5th Petts Wood Scout Troop</Typography>
        <Typography>Many thanks to 5th Petts Wood Scouts for the hire of their minibuses for the London 2022 Event, to allow us to run a shuttle service for the girls.</Typography>
        <Typography variant='h6' component='body' className={classes.smallMarginTop}>Friends of Crofton Oak</Typography>
        <Typography>Many thanks to Friends of Crofton Oak for the loan of their mess tent for the London 2022 Event.</Typography>
        <Typography variant='h6' component='body' className={classes.smallMarginTop}>Bath District Scouts</Typography>
        <Typography>Thanks to Bath District Scouts for the loan of some tents, and various smaller pieces of kit, for the London and Avon 2022 Events, as well as the use of Cleeve Hill Campsite for the Avon 2022 Event.</Typography>
        <Typography variant='h6' component='body' className={classes.smallMarginTop}>2nd Bath Boys Brigade</Typography>
        <Typography>Thanks to 2nd Bath Boys Brigade for the loan of their Resussi Annies for the London and Avon 2022 Events.</Typography>
        <Typography variant='h6' component='body' className={classes.smallMarginTop}>Bromley District Scouts</Typography>
        <Typography>Thanks to Bromley District Scouts for the loan of their Resussi Annies for the Avon 2022 Event.</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Team);
