/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Link, Popover, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { capitaliseWord } from '../../utils/capitaliseWord';
// import OneWayImage from '../../images/one-way-parking.png';

const styles = (theme) => ({
  mainTitle: {
    marginBottom: '1rem',
  },
  locationContainer: {
    marginBottom: '2em',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'justify',
  },
  sectionTitle: {
    fontWeight: '700',
  },
  sectionContent: {
    marginBottom: '1em',
  },
  map: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  link: {
    fontWeight: 700,
  },
  what3WordsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5em',
  },
  infoBoxIcon: {
    padding: 0,
  },
  infoBoxPaper: {
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
  },
  infoBoxTextWrapper: {
    padding: theme.spacing(2),
    textAlign: 'justify',
  },
  busStopListHeader: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  busStopListItemIcon: {
    minWidth: '36px',
    color: theme.palette.primary.main,
  },
  busStopList: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  busStopSubList: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: '75px',
  },
  boldText: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  urgentUpdate: {
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: '20px',
    padding: '1em',
    marginBottom: '1em',
  },
});

class LondonLocationInfo extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      location: routes[1],
      infoBoxOpen: false,
      anchorEl: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Location`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/location` });
  }

  handleClick(event) {
    ReactGA.event({
      category: 'More info',
      action: 'What3Words',
      label: 'Open What3Words Help Box',
    });
    this.setState({
      anchorEl: event.currentTarget,
      infoBoxOpen: true,
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
      infoBoxOpen: false,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h4" className={classes.mainTitle}>Cudham Shaws Outdoor Centre</Typography>
        <Grid container spacing={1} className={classes.locationContainer} justifyContent="space-evenly">
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant='h6' className={classes.sectionTitle}>Address</Typography>
            <Typography variant='h6' component='body'>144 Cudham Lane North, Cudham, Kent, TN14 7QT</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <div className={classes.what3WordsHeader}>
              <Typography variant='h6' className={classes.sectionTitle}>What Three Words</Typography>
              <IconButton
                className={classes.infoBoxIcon}
                aria-describedby={'iconBox'}
                color="primary"
                onClick={this.handleClick}
              >
                <InfoOutlinedIcon />
              </IconButton>
              <Popover
                id={'iconBox'}
                open={this.state.infoBoxOpen}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                PaperProps={{
                  className: classes.infoBoxPaper,
                }}
              >
                <div className={classes.infoBoxTextWrapper}>
                  <Typography className={classes.infoBoxText}>what3words divides the world into 3 metre squares and gives each square a unique combination of three words. It’s the easiest way to find and share exact locations.</Typography>
                  <Typography className={classes.infoBoxText}>Find out more at <Link href='https://what3words.com/about' className={classes.link}>https://what3words.com/about</Link>.</Typography>
                </div>
              </Popover>
            </div>
            <Typography variant='h6' component='body'><Link href='https://what3words.com/store.stacks.exam' target='_blank' rel="noopener noreferrer" className={classes.link}>{'///store.stacks.exam'}</Link></Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6' className={classes.sectionTitle}>Lat/Long</Typography>
            <Typography variant='h6' component='body'>N51° 19.637′ E000° 04.370′</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant='h6' className={classes.sectionTitle}>Grid Reference</Typography>
            <Typography variant='h6' component='body'>TQ444607</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item sm={12} md={8}>
            <div>
              <Typography variant='h5' component='h2'>Parking</Typography>
              <Typography>Parking for leaders and drop off/pick up for participants will take place on the top site at Cudham - this is the entrance on Cudham Lane North, NOT the entrance on Downe Road.</Typography>
              <Typography>We will be operating a one-way system on the site for drop-off and pick-up - please follow the directions of our volunteers at all times. Please note that you will be parking in a field.</Typography>
              <Typography className={classes.sectionContent}>When collecting your participant(s) at the end of the event, please wait with your car for the groups to come to you.</Typography>

              {/* <div className={classes.urgentUpdate}>
                <Typography><span className={classes.boldText}>URGENT UPDATE:</span> The field we were planning on using for the Be Prepared parent drop off has become too boggy in the rain and we are unable to drive on it.</Typography>
                <Typography>All leaders cars will be parked on the LOWER site (Downe Road, BR6 7LH, <Link href='https://what3words.com/goats.metro.since' target='_blank' rel="noopener noreferrer" className={classes.link}>{'///goats.metro.since'}</Link>). Please ensure that one leader from your unit is on site at 08:30, as we have limited space for parents to park and therefore need each participant to be given to a unit leader as soon as they arrive.</Typography>
                <Typography>We will also be splitting the units drop off points between the upper and lower sites. Leaders have all been emailed letting them know which site their unit has been assigned - if you are a parent, please check with your unit leader to ensure you go to the right entrance.</Typography>
              </div> */}
              <Typography variant='h5' component='h2'>Minibuses</Typography>
              <Typography className={classes.sectionContent}>If you are coming by minibus, please let us know in advance so we can arrange suitable parking.</Typography>

              <Typography variant='h5' component='h2'>Coaches</Typography>
              <Typography className={classes.sectionContent}>If you are planning to bring any vehicles larger than a minibus, please speak to us in advance so we can arrange a suitable location for you to drop off, as you will be unable to access the site.</Typography>

              <Typography variant='h5' component='h2'>Train</Typography>
              <Typography>The closest train stations to the campsite are Orpington, Chelsfield, and Knockholt. All three stations are in Zone 6 and can be accessed from various London stations.</Typography>
              <Typography className={classes.sectionContent}>Buses between these stations and the campsite are relatively infrequent so, if you would like to come by train, please let us know your numbers when you book and we will arrange to pick you up.</Typography>

              {/* <Typography variant='h5' component='h2'>Camping</Typography>
              <Typography className={classes.sectionContent}>Cudham Shaws are offering reduced camping fees (£4 per Guide/Scout and free for leaders) for units who wish to stay overnight on the lower field - leaders will be responsible for their unit and must have their own camping permit. Once you have signed up to attend Be Prepared, you may book for your unit to camp at Cudham <Link href="https://www.cudhamshaws.site/bookings.html" target='_blank' rel="noopener noreferrer" className={classes.link}>via their usual booking process</Link>, making sure you choose the Be Prepared camping option.</Typography> */}
            </div>
          </Grid>
          {/* <Grid item sm={12} md={4}>
            <div className={classes.map}>
              <img src={OneWayImage} alt="One way parking for drop-off and pick-up" width="100%"/>
            </div>
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(LondonLocationInfo);
