import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link, List, ListItem } from '@material-ui/core';
import { getAllPagesInOrder } from '../../utils/enums/Pages';

const styles = () => ({
  listItem: {
    padding: '5px',
    marginBottom: '5px',
    marginRight: '15px',
    marginLeft: '15px',
  },
  link: {
    margin: 'auto',
    color: 'white',
    whiteSpace: 'nowrap',
    '&:focus-visible': {
      outline: '3px solid white',
    },
  },
  horizontalList: {
    display: 'flex',
    padding: 0,
    width: '100%',
  },
});

const publicPages = getAllPagesInOrder(false, '', false);

class SecondAppBarLinks extends React.Component {
  constructor(props) {
    super(props);
    this.isCurrentPage = this.isCurrentPage.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  isCurrentPage(pageRoute) {
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    return currentPath === `/${routes[1]}${pageRoute}`;
  }

  handleClick(destination) {
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.props.history.push(`/${routes[1]}${destination}`);
  }

  keyDown(e, url) {
    if (e.charCode === 13) {
      this.handleClick(url);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <List className={classes.horizontalList}>
        {publicPages.map(page => (
          <ListItem
            style={{ borderBottom: this.isCurrentPage(page.clickDestination) ? '3px solid #c3c3c3' : '3px solid #4d2177' }}
            className={classes.listItem}
            key={page.pageName}
          >
            <Link
              onClick={() => this.handleClick(page.clickDestination)}
              onKeyPress={(e) => this.keyDown(e, page.clickDestination)}
              className={classes.link}
              underline='never'
            >
              {page.pageName}
            </Link>
          </ListItem>
        ))}
      </List>
    );
  }
}

export default withRouter(withStyles(styles)(SecondAppBarLinks));
