import React from 'react';
import { withRouter } from 'react-router-dom';
import { IconButton, ListItemIcon, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { getAllPagesInOrder } from '../../utils/enums/Pages';

class MobileMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      publicPages: getAllPagesInOrder(true, this.props.location.pathname.split('/')[1], true),
      menuOpen: false,
      anchorEl: null,
    };
    this.goToLink = this.goToLink.bind(this);
    this.isActiveLink = this.isActiveLink.bind(this);
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
  }

  goToLink(link) {
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    if (link === '/' || link == '/past-events') {
      this.props.history.push(link);
    } else {
      this.props.history.push(`/${routes[1]}${link}`);
    }
  }

  isActiveLink(link) {
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    return this.props.location.pathname === `/${routes[1]}${link}`;
  }

  handleMenuClick(event) {
    this.setState({
      menuOpen: true,
      anchorEl: event.target,
    });
  }

  handleItemClick(link) {
    this.goToLink(link);
    this.setState({
      menuOpen: false,
      anchorEl: null,
    });
  }

  handleMenuClose() {
    this.setState({
      menuOpen: false,
      anchorEl: null,
    });
  }

  render() {
    return (
      <>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={this.handleMenuClick}
        >
          <MenuIcon style={{ marginRight: '20px', marginBottom: '5px' }} />
        </IconButton>
        <Menu
          anchorEl={this.state.anchorEl}
          keepMounted
          open={this.state.menuOpen}
          onClose={this.handleMenuClose}
        >
          {this.state.publicPages.map(page => (
            <MenuItem
              disabled={this.isActiveLink(page.clickDestination)}
              onClick={() => this.handleItemClick(page.clickDestination)}
              key={page.pageName}
            >
              <ListItemIcon>{page.icon}</ListItemIcon>
              {page.pageName}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }
}

export default withRouter(MobileMenu);
