import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter, Redirect } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';

import { AppBar, Toolbar, CssBaseline, Link, Hidden } from '@material-ui/core/';
import FooterLinks from './components/FooterLinks';
import BePreparedLogo from './images/logos/be-prepared/white-logo.png';
import BexleyScoutLogo from './images/logos/london/bexley-scout-logo-white.png';
import GLKLogo from './images/logos/london/GLK-logo-white.png';
import BathScoutLogo from './images/logos/avon/bath-scouts.png';
import GGSomersetNorthLogo from './images/logos/avon/somerset-north-logo.png';

import Home from './containers/Home';
import EventDetails from './containers/EventDetails';
import LondonLocationInfo from './containers/LocationInfo/LondonLocationInfo';
import SignUp from './containers/SignUp';
import RelatedBadges from './containers/RelatedBadges';
import Volunteer from './containers/Volunteer';
import LondonFaqs from './containers/FAQs/LondonFaqs';
import AvonFaqs from './containers/FAQs/AvonFaqs';
import ContactUs from './containers/ContactUs';
import LondonHomepage from './containers/LocationHomepages/LondonHomepage';
import AvonHomepage from './containers/LocationHomepages/AvonHomepage';
import ErrorRoute from './containers/ErrorRoute';
import SecondAppBar from './components/SecondAppBar';
import Team from './containers/Team';
import GirlFeedback from './containers/GirlFeedback';
import LeaderFeedback from './containers/LeaderFeedback';
import PastEvents from './containers/PastEvents';
import locations from './utils/enums/locations';
import AvonLocationInfo from './containers/LocationInfo/AvonLocationInfo';

const styles = theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    paddingBottom: '0px',
  },
  pageContent: {
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    paddingBottom: 0, // footer divider deals with spacing at the bottom
    minHeight: 'calc(100vh - 290px)',
  },
  tabableImg: {
    '&:focus-visible': {
      outline: '3px solid white',
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.backToHome = this.backToHome.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  backToHome() {
    this.props.history.push('/');
  }

  keyDown(e) {
    if (e.charCode === 13) {
      this.backToHome();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className = "App">
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="absolute">
            <Toolbar style={{ minHeight: '120px' }}>
              <Hidden xsDown>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  {this.props.location.pathname.includes('london') && (
                    <Link
                      href='https://www.glkguides.org.uk/'
                      target='_blank'
                      rel="noopener noreferrer"
                      className={classes.tabableImg}
                    >
                      <img src={GLKLogo} alt={'girlguiding glk logo'} width='180' />
                    </Link>
                  )}
                  {this.props.location.pathname.includes('avon') && (
                    <Link
                      href='https://girlguidingsomersetnorth.org.uk/'
                      target='_blank'
                      rel="noopener noreferrer"
                      className={classes.tabableImg}
                    >
                      <img src={GGSomersetNorthLogo} alt={'girlguiding somerset north logo'} width='180' />
                    </Link>
                  )}
                  <Link
                    onClick={this.backToHome}
                    onKeyPress={this.keyDown}
                    className={classes.tabableImg}
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  >
                    <img src={BePreparedLogo} alt={'be prepared logo'} height='100' />
                  </Link>
                  {this.props.location.pathname.includes('london') && (
                    <Link
                      href='https://www.bexleyscouts.org.uk/'
                      target='_blank'
                      rel="noopener noreferrer"
                      className={classes.tabableImg}
                    >
                      <img src={BexleyScoutLogo} alt={'bexley scouts logo'} width='180' />
                    </Link>
                  )}
                  {this.props.location.pathname.includes('avon') && (
                    <Link
                      href='https://www.bathscouts.org/'
                      target='_blank'
                      rel="noopener noreferrer"
                      className={classes.tabableImg}
                    >
                      <img src={BathScoutLogo} alt={'bath scouts logo'} width='180' />
                    </Link>
                  )}
                </div>
              </Hidden>
              <Hidden smUp>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  {this.props.location.pathname.includes('london') && !this.props.location.pathname.includes('avon') && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '0.5em' }}>
                      <Link
                        href='https://www.glkguides.org.uk/'
                        target='_blank'
                        rel="noopener noreferrer"
                      >
                        <img src={GLKLogo} alt={'girlguiding glk logo'} height='35' />
                      </Link>
                      <Link
                        href='https://www.bexleyscouts.org.uk/'
                        target='_blank'
                        rel="noopener noreferrer"
                      >
                        <img src={BexleyScoutLogo} alt={'bexley district scouts logo'} height='45' />
                      </Link>
                    </div>
                  )}
                  {this.props.location.pathname.includes('avon') && !this.props.location.pathname.includes('london') && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: '0.5em' }}>
                      <Link
                        href='https://girlguidingsomersetnorth.org.uk/'
                        target='_blank'
                        rel="noopener noreferrer"
                      >
                        <img src={GGSomersetNorthLogo} alt={'girlguiding somerset north county logo'} height='35' />
                      </Link>
                      <Link
                        href='https://www.bathscouts.org/'
                        target='_blank'
                        rel="noopener noreferrer"
                      >
                        <img src={BathScoutLogo} alt={'bath district scouts logo'} height='45' />
                      </Link>
                    </div>
                  )}
                  <div style={{ display: 'flex', width: '100%' }}>
                    <Link
                      onClick={this.backToHome}
                      onKeyPress={this.keyDown}
                      className={classes.tabableImg}
                      style={{ marginLeft: 'auto', marginRight: 'auto' }}
                    >
                      <img src={BePreparedLogo} alt={'be prepared logo'} height='70' />
                    </Link>
                  </div>
                </div>
              </Hidden>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.toolbar} style={{ minHeight: '120px' }} />
            {locations.includes(this.props.location.pathname.split('/')[1]) ? <SecondAppBar /> : null}
            <div className={classes.pageContent}>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/past-events" component={PastEvents} />
                <Route exact path="/bristol" render={() => (<Redirect to="/avon" />)} />
                <Route exact path="/london" component={LondonHomepage} />
                <Route exact path="/avon" component={AvonHomepage} />
                <Route exact path={`/:location(${locations.join('|')})/event-details`} component={EventDetails} />
                <Route exact path="/london/location" component={LondonLocationInfo} />
                <Route exact path="/avon/location" component={AvonLocationInfo} />
                <Route exact path={`/:location(${locations.join('|')})/team`} component={Team} />
                <Route exact path={`/:location(${locations.join('|')})/sign-up`} component={SignUp} />
                <Route exact path={`/:location(${locations.join('|')})/badges`} component={RelatedBadges} />
                <Route exact path={`/:location(${locations.join('|')})/volunteer`} component={Volunteer} />
                <Route exact path="/london/faq" component={LondonFaqs} />
                <Route exact path="/avon/faq" component={AvonFaqs} />
                <Route exact path={`/:location(${locations.join('|')})/contact`} component={ContactUs} />
                <Route exact path={`/:location(${locations.join('|')})/feedback`} component={GirlFeedback} />
                <Route exact path={`/:location(${locations.join('|')})/leader-feedback`} component={LeaderFeedback} />
                <Route component={ErrorRoute} />
              </Switch>
            </div>
            <FooterLinks/>
          </main>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(App));
