/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Card, CardHeader, CardContent, CardActions, Typography, Button, Link } from '@material-ui/core';
import PinDropIcon from '@material-ui/icons/PinDrop';
import HistoryIcon from '@material-ui/icons/History';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import LondonMap from '../images/locations/london_map.png';
import BathMap from '../images/locations/bath_map.png';

const styles = (theme) => ({
  cardContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: '1em',
    flexWrap: 'wrap',
  },
  card: {
    width: 'min(95vw, 500px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  cardContent: {
    textAlign: 'center',
  },
  cardActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    margin: '10px',
  },
  link: {
    fontWeight: 700,
  },
  headerTitle: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.5em',
  },
  subHeader: {
    textAlign: 'center',
  },
  previousLocationHeader: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    marginTop: '0.5em',
  },
  nextLocationHeader: {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '1.5em',
  },
  nextLocationDetails: {
    fontSize: '1.25em',
  },
  nextLocationBox: {
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    padding: '5px',
  },
  icon: {
    color: '#737373',
  },
  facebookIcon: {
    color: theme.palette.action.disabled,
    '&:hover': {
      color: '#4267B2',
    },
    '&:focus-visible': {
      color: '#4267B2',
    },
    margin: '0 5px',
    cursor: 'pointer',
  },
  instagramIcon: {
    color: theme.palette.action.disabled,
    '&:hover': {
      color: '#c13584',
    },
    '&:focus-visible': {
      color: '#c13584',
    },
    margin: '0 5px',
    cursor: 'pointer',
  },
  emailIcon: {
    color: theme.palette.action.disabled,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:focus-visible': {
      color: theme.palette.primary.main,
      outline: `3px solid ${theme.palette.primary.main}`,
    },
    margin: '0 5px',
    cursor: 'pointer',
  },
  button: {
    marginTop: '2em',
  },
});

const pageInfo = [
  {
    Icon: PinDropIcon,
    name: 'Be Prepared Avon',
    lastEventDate: '7th & 8th October 2023',
    lastEventLocation: 'Cleeve Hill Scout Campsite',
    nextEventDate: 'TBC',
    nextEventLocation: 'TBC',
    map: BathMap,
    supportedBy: 'Girlguiding Somerset North County and Bath District Scouts',
    linkLocation: '/avon',
    facebookLink: 'https://www.facebook.com/bepreparedevent.avon',
    instagramLink: 'https://www.instagram.com/bepreparedevent.avon',
    contactLink: '/avon/contact',
  }, {
    Icon: PinDropIcon,
    name: 'Be Prepared London',
    lastEventDate: '1st & 2nd April 2023',
    lastEventLocation: 'Cudham Shaws Outdoor Centre',
    nextEventDate: 'TBC',
    nextEventLocation: 'TBC',
    map: LondonMap,
    supportedBy: 'Girlguiding Greater London Kent County and Bexley District Scouts',
    linkLocation: '/london',
    facebookLink: 'https://www.facebook.com/beprepared.london',
    instagramLink: 'https://www.instagram.com/beprepared.london',
    contactLink: '/london/contact',
  }, {
    Icon: HistoryIcon,
    name: 'Past Events',
    information: 'See photos and unit lists from all our previous events',
    linkLocation: '/past-events',
  },
];

class Home extends React.Component {
  componentDidMount() {
    document.title = 'Be Prepared Resilience Event';
    ReactGA.send({ hitType: 'pageview', page: '/' });
  }

  registerClickToSocialMedia(type) {
    ReactGA.event({
      category: 'Social Media',
      action: 'View profile',
      label: type,
    });
  }

  visitPage(url) {
    this.props.history.push(url);
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <div className={classes.cardContainer}>
          {pageInfo.map(card => (
            <Card raised key={card.name} className={classes.card}>
              <CardHeader
                classes={{ title: classes.headerTitle, subheader: classes.subHeader }}
                title={card.name}
                subheader={card.supportedBy && `Supported by: ${card.supportedBy}`}
                avatar={<card.Icon fontSize='large' className={classes.icon} />}
              />
              <CardContent className={classes.cardContent}>
                {card.map ? (
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <img src={card.map} alt={`${card.name} map`} height='150px'/>
                    <div>
                      <div className={classes.nextLocationBox}>
                        <Typography className={classes.nextLocationHeader}>Next event</Typography>
                        <Typography className={classes.nextLocationDetails}>{card.nextEventDate === 'TBC' ? 'Watch this space…' : `${card.nextEventDate} at ${card.nextEventLocation}`}</Typography>
                      </div>
                      <Typography className={classes.previousLocationHeader}>Last event</Typography>
                      <Typography>{card.lastEventDate} at {card.lastEventLocation}</Typography>
                    </div>
                  </div>
                ) : <Typography>{card.information}</Typography>}
              </CardContent>
              <CardActions className={classes.cardActions}>
                <div>
                  {card.facebookLink && (
                    <Link
                      href={card.facebookLink}
                      aria-label={`go to ${card.name} facebook`}
                      target='_blank'
                      rel="noopener noreferrer"
                      className={classes.facebookIcon}
                      onClick={() => this.registerClickToSocialMedia(`${card.name} Facebook`)}
                    >
                      <FacebookIcon/>
                    </Link>
                  )}
                  {card.instagramLink && (
                    <Link
                      href={card.instagramLink}
                      aria-label={`go to ${card.name} instagram`}
                      target='_blank'
                      rel="noopener noreferrer"
                      className={classes.instagramIcon}
                      onClick={() => this.registerClickToSocialMedia(`${card.name} Instagram`)}
                    >
                      <InstagramIcon/>
                    </Link>
                  )}
                  {card.contactLink && (
                    <MailOutlineIcon
                      className={classes.emailIcon}
                      tabIndex={0}
                      onClick={() => {this.registerClickToSocialMedia(`Mail ${card.name}`); this.visitPage(card.contactLink);}}
                      onKeyPress={(e) => this.keyDown(e, card.contactLink)}
                      aria-label={`contact ${card.name}`}
                    />
                  )}
                </div>
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.button}
                  aria-label={`Go to ${card.name}`}
                  onClick={() => this.visitPage(card.linkLocation)}
                  endIcon={<ArrowForwardIcon />}
                >
                  Go
                </Button>
              </CardActions>
            </Card>
          ))}
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(Home));
