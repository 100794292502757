/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Grid, IconButton, Link, Popover, Typography } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { capitaliseWord } from '../../utils/capitaliseWord';

const styles = (theme) => ({
  mainTitle: {
    marginBottom: '1rem',
  },
  locationContainer: {
    marginBottom: '2em',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    textAlign: 'justify',
  },
  sectionTitle: {
    fontWeight: '700',
  },
  sectionContent: {
    marginBottom: '1em',
  },
  map: {
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  link: {
    fontWeight: 700,
  },
  what3WordsHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5em',
  },
  infoBoxIcon: {
    padding: 0,
  },
  infoBoxPaper: {
    [theme.breakpoints.up('sm')]: {
      width: '30%',
    },
  },
  infoBoxTextWrapper: {
    padding: theme.spacing(2),
    textAlign: 'justify',
  },
  busStopListHeader: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  busStopListItemIcon: {
    minWidth: '36px',
    color: theme.palette.primary.main,
  },
  busStopList: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  busStopSubList: {
    paddingBottom: 0,
    paddingTop: 0,
    paddingLeft: '75px',
  },
});

class AvonLocationInfo extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      location: routes[1],
      infoBoxOpen: false,
      anchorEl: null,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Location`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/location` });
  }

  handleClick(event) {
    ReactGA.event({
      category: 'More info',
      action: 'What3Words',
      label: 'Open What3Words Help Box',
    });
    this.setState({
      anchorEl: event.currentTarget,
      infoBoxOpen: true,
    });
  }

  handleClose() {
    this.setState({
      anchorEl: null,
      infoBoxOpen: false,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div>
        <Typography variant="h4" classNme={classes.mainTitle}>Cleeve Hill Scout Campsite</Typography>
        <Grid container spacing={1} className={classes.locationContainer} justifyContent="space-evenly">
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant='h6' className={classes.sectionTitle}>Address</Typography>
            <Typography variant='h6' component='body'>Kelston Road, Bath, BA1 9AB</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <div className={classes.what3WordsHeader}>
              <Typography variant='h6' className={classes.sectionTitle}>What Three Words</Typography>
              <IconButton
                className={classes.infoBoxIcon}
                aria-describedby={'iconBox'}
                color="primary"
                onClick={this.handleClick}
              >
                <InfoOutlinedIcon />
              </IconButton>
              <Popover
                id={'iconBox'}
                open={this.state.infoBoxOpen}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                PaperProps={{
                  className: classes.infoBoxPaper,
                }}
              >
                <div className={classes.infoBoxTextWrapper}>
                  <Typography className={classes.infoBoxText}>what3words divides the world into 3 metre squares and gives each square a unique combination of three words. It’s the easiest way to find and share exact locations.</Typography>
                  <Typography className={classes.infoBoxText}>Find out more at <Link href='https://what3words.com/about' className={classes.link}>https://what3words.com/about</Link>.</Typography>
                </div>
              </Popover>
            </div>
            <Typography variant='h6' component='body'><Link href='https://what3words.com/cards.code.brand' target='_blank' rel="noopener noreferrer" className={classes.link}>{'///cards.code.brand'}</Link></Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant='h6' className={classes.sectionTitle}>Lat/Long</Typography>
            <Typography variant='h6' component='body'>51°23′36″N, 002°24′28″W</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography variant='h6' className={classes.sectionTitle}>Grid Reference</Typography>
            <Typography variant='h6' component='body'>ST 71727 66104</Typography>
          </Grid>
        </Grid>

        <Grid container className={classes.container}>
          <Grid item sm={12} md={8}>
            <div>
              <Typography variant='h5' component='h2'>Parent Drop Off</Typography>
              <Typography>Parent drop off will be at Oldfield School (<Link href='https://what3words.com/camps.couches.half' target='_blank' rel="noopener noreferrer" className={classes.link}>{'///camps.couches.half'}</Link>). Unit leaders must greet their participants at the school, and then walk to the main Cleeve Hill entrance (approx. 200m). Volunteers will be on hand to help direct traffic, and point you in the right direction to the campsite.</Typography>
              <Typography className={classes.sectionContent}>If any of your Young People hold a blue badge, and require vehicle access to the site, please let us know when you book, and they will be able to drive into Cleeve Hill to drop off.</Typography>

              <Typography variant='h5' component='h2'>Leaders Parking</Typography>
              <Typography>Unit leaders may park on site at Cleeve Hill if they wish. Please come to the main entrance of Cleeve Hill (<Link href='https://what3words.com/cards.code.brand' target='_blank' rel="noopener noreferrer" className={classes.link}>{'///cards.code.brand'}</Link>) and follow the signs and instructions of the marshals. Leaders must arrive before 08:40 to park, so there are no cars moving on site when the participants arrive. No leaders cars will be allowed to leave until all participants are out of the car park at the end of the event.</Typography>
              <Typography className={classes.sectionContent}>If you do not wish to park in the field, or need to move your car during the event, you will need to find on-street parking nearby.</Typography>

              <Typography variant='h5' component='h2'>Minibuses</Typography>
              <Typography className={classes.sectionContent}>If you are coming by minibus, please come to the main entrance of Cleeve Hill (<Link href='https://what3words.com/cards.code.brand' target='_blank' rel="noopener noreferrer" className={classes.link}>{'///cards.code.brand'}</Link>) where you can park in the car park. We recommend that you approach from the school, and do a right turn into the campsite, as the left turn is quite tight in a minibus. Please follow the directions of the marshals, as we may have multiple minibuses we need to fit in the car park.</Typography>

              <Typography variant='h5' component='h2'>Coaches</Typography>
              <Typography>If you are planning to bring any vehicles larger than a minibus please ask your driver to stop in front of Oldfield School to unload your passengers, and then walk up the road to the campsite.</Typography>
              <Typography>Please let us know your rough arrival time, and a marshal will meet you to help manage traffic and escort your group onto site.</Typography>
              <Typography className={classes.sectionContent}>There is no parking available for coaches to stay on site for the duration of the event - your coach driver may be able to find on-street parking, or they can use the coach park in Bath (<Link href='https://what3words.com/dash.verbs.pocket' target='_blank' rel="noopener noreferrer" className={classes.link}>{'///dash.verbs.pocket'}</Link>).</Typography>

              <Typography variant='h5' component='h2'>Train or Bus</Typography>
              <Typography className={classes.sectionContent}>If you would like to come by train, the nearest station is Bath Spa, in the centre of town. You can then get on the <Link href="https://bustimes.org/services/19-bath-kingswood-downend-uwe-cribbs-causeway" target='_blank' rel="noopener noreferrer" className={classes.link}>number 19 bus</Link> from Bath Bus Station to &quot;Newbridge Oldfield School (NW-bound)&quot; (approx. 15 minutes). This bus runs runs between Bath Bus Station and Cribbs Causeway once an hour at the weekends.</Typography>
            </div>
          </Grid>
          {/* <Grid item sm={12} md={4}>
            <div className={classes.map}>
              <iframe src="https://www.google.com/maps/d/u/4/embed?mid=1fsxPqsbS_abaB7gDqCRDQHr8sRA7Aic&ehbc=2E312F" width="100%" height="600"></iframe>
            </div>
          </Grid> */}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AvonLocationInfo);
