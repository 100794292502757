/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Link, Typography } from '@material-ui/core';
import CustomLink from '../components/CustomLink';
import { getEventFeedbackFormLeaders } from '../utils/locationSpecificInfo';
import { capitaliseWord } from '../utils/capitaliseWord';

const styles = () => ({
  justifyText: {
    textAlign: 'justify',
  },
  underline: {
    textDecoration: 'underline',
  },
  newWindowLink: {
    textAlign: 'right',
    margin: '2em 0 0.5em',
  },
  link: {
    fontWeight: 700,
  },
  breakAnywhere: {
    wordBreak: 'break-all', // link text is really long with no spaces and so caused overflow problems on mobile
  },
});

class LeaderFeedback extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      location: routes[1],
    };
    this.registerOpenedInNewTab = this.registerOpenedInNewTab.bind(this);
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Leader Feedback`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/leader-feedback` });
  }

  registerOpenedInNewTab() {
    ReactGA.event({
      category: 'Forms',
      action: 'Open in new tab',
      label: 'Leader Feedback',
    });
  }

  render() {
    const { classes } = this.props;
    const feedbackForm = getEventFeedbackFormLeaders(this.state.location);
    return (
      <div>
        <Typography variant="h4">Feedback - Leaders</Typography>
        <Typography className={classes.justifyText}>We hope your Scouts/Guides enjoyed Be Prepared. Please fill in the following feedback form to help us improve the event for future years.</Typography>
        <Typography className={classes.justifyText}>The participants can fill out an individual feedback form at <CustomLink linkText={`www.beprepared.org.uk/${this.state.location}/feedback`} linkLocation={`/${this.state.location}/feedback`} /> - feel free to assist them in doing this, or collect answers in a meeting and then fill out the form yourself.</Typography>
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6}>
            <Typography className={classes.newWindowLink}>
              <Link href={feedbackForm.shortLink} target='_blank' rel="noopener noreferrer" className={classes.link} onClick={this.registerOpenedInNewTab}>Open form in new window</Link>
            </Typography>
            <iframe src={feedbackForm.longLink} width="100%" height="500" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
          </Grid>
        </Grid>
        <div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(LeaderFeedback);
