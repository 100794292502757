/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Link, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';
import RoomIcon from '@material-ui/icons/Room';
import CustomLink from '../../components/CustomLink';
import RiskAssessment from '../../documents/Be Prepared Avon Risk Assessment.pdf';
import { capitaliseWord } from '../../utils/capitaliseWord';
import { getEmailAddress } from '../../utils/locationSpecificInfo';

const styles = (theme) => ({
  question: {
    marginTop: '1em',
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  answer: {
    textAlign: 'justify',
  },
  listItem: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemIcon: {
    minWidth: '36px',
    color: theme.palette.primary.main,
  },
  link: {
    fontWeight: 700,
  },
  important: {
    fontWeight: 700,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
});

const campsites = [
  {
    name: 'Bath Scout HQ, Grove Street',
    link: 'https://www.bathscouts.org/about-us/district-hq',
    description: 'Indoors, 10 minute drive, 30 minutes by bus',
  },
  {
    name: 'Briarlands Activity Centre',
    link: 'https://briarlands.com/',
    description: 'Indoors and camping, 10 minute drive',
  },
  {
    name: 'Chelwood Scout Campsite',
    link: 'https://www.1stkeynshamscouts.org.uk/lettings/chelwood/',
    description: 'Camping, 15 minute drive',
  },
  {
    name: 'Eastwinds Scout Centre',
    link: 'https://www.bristolsouthscouts.org.uk/east_winds',
    description: 'Indoors, 20 minute drive',
  },
  {
    name: 'Woodhouse Park Scout Campsite',
    link: 'https://woodhousepark.org.uk',
    description: 'Indoors and camping, 30 minute drive',
  },
];

class AvonFaqs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: 'avon',
    };
    this.registerRAClick = this.registerRAClick.bind(this);
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Questions`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/faq` });
  }

  registerRAClick() {
    ReactGA.event({
      category: 'Files',
      action: 'Risk Assessment',
      label: `View ${capitaliseWord(this.state.location)} Risk Assessment`,
    });
  }

  registerCampsiteClick(campsiteName) {
    ReactGA.event({
      category: 'Campsites',
      action: 'View Campsite',
      label: campsiteName,
    });
  }

  render() {
    const { classes } = this.props;
    const emailAddress = getEmailAddress(this.state.location);
    return (
      <div>
        <Typography variant="h4" component='h1'>FAQs</Typography>
        <Typography>If your question is not answered below, please <CustomLink linkText='get in touch' linkLocation={`/${this.state.location}/contact`} /> and we will do our best to answer your query.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>When will booking close?</Typography>
        <Typography className={classes.answer}>Bookings for Be Prepared Avon 2023 have now closed.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>When do I need to pay?</Typography>
        <Typography className={classes.answer}>We will send you an invoice after you complete the <CustomLink linkText='sign up form' linkLocation={`/${this.state.location}/sign-up`} /> and you will have 28 days to make the payment shown. Please check your spam folder for the invoice, and add <Link href={`mailto:${emailAddress}`} className={classes.link}>{emailAddress}</Link> to your safe senders list so you don&apos;t miss any important emails from us.  If you sign up less than 28 days before the event, you must have paid before you arrive on site.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>What happens if I don&apos;t pay on time?</Typography>
        <Typography className={classes.answer}>If your invoice is not paid by the date shown on it (28 days from creation), and we have not heard from you, then you may lose the spaces you had booked and will need to contact us to book your spaces again. If the event is oversubscribed, we will operate a waiting list and your spaces may have already been given to another unit.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>I am not based in Bath, can I book onto the event?</Typography>
        <Typography className={classes.answer}>Yes, the event is open to all units across the UK. If you are based near to London, you may wish to consider attending the <CustomLink linkText='London event' linkLocation={'/london'} /> in the Borough of Bromley instead.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>What happens if the event is full up when I go to book?</Typography>
        <Typography className={classes.answer}>We will operate a waiting list for each day, once all the spaces have been filled. In the event of another unit not paying for their spaces in the timeframe required, or dropouts, we will work down the waiting list offering the available spaces. If you are on the waiting list and are not offered a space for this event, then spaces for the next event will be offered to you one week before general booking opens.</Typography>
        <Typography>Please note: we may offer you spaces with short notice, if there are last minute drop-outs in the lead up to the event - we will confirm when adding you to the waiting list whether you are able to accept these last minute spaces or not.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>Someone that I paid for can&apos;t come, can I have a refund?</Typography>
        <Typography className={classes.answer}>If we are unable fill the space, then unfortunately we will not be able to refund you. If we are able to fill the space, then we will endeavour to refund you.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>Are the bases the same as last year?</Typography>
        <Typography className={classes.answer}>The bases are similar to last year with some new bases, however participants who have been before are still welcome to come again. As they know what to expect, they can now take on more of a leadership role within their family, and may stand a higher chance of winning the prize for teamwork. They will also be in a group with different participants, so their solutions to each challenge may be different.</Typography>

        {/* <Typography variant='h5' component='h2' className={classes.question}>When can we arrive?</Typography>
        <Typography className={classes.answer}>The gates to Cleeve Hill will be open to unit leaders from 08:45. Our marshals will be waiting at Oldfield School at 09:00, to walk Young People and leaders onto site - unit leaders must be waiting to greet their participants at the school at this time. Please do not arrive early as you will be unable to access the site.</Typography> */}

        <Typography variant='h5' component='h2' className={classes.question}>Where do we go on arrival?</Typography>
        <Typography className={classes.answer}>We are looking into the safest and most efficient way to manage parent drop off, leaders will be given full information when we have a plan in place. More information will also be put on the <CustomLink linkText='location page' linkLocation={`/${this.state.location}/location`} />.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>What should we wear?</Typography>
        <Typography className={classes.answer}>Where possible, participants should be in uniform or branded clothing. Either walking boots or wellies is a must, especially if the ground is slightly damp. Appropriate waterproofs and/or sun protection should also be worn.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>What do we need to bring?</Typography>
        <Typography className={classes.answer}>Each participant (and leaders) will need to bring waterproofs and/or sun protection, a water bottle, and a packed lunch.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>Are refreshments available?</Typography>
        <Typography className={classes.answer}>Tea, coffee, and soft drinks will be available for leaders throughout the day. During breaks and lunch, squash will be available for the Young People (plus biscuits during the breaks). There will be a tap for refilling water bottles.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>Will my participants be split up?</Typography>
        <Typography className={classes.answer}>Yes, your participants will be split between the different families. This is to simulate being separated from people they know during a disaster, and allows participants to develop their teamwork skills. If you have a participant who would benefit from being with one other person they know, then please let us know when we ask for your participants names. Your unit will be able to spend breaks and lunches together.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>How will I recognise the volunteers at the event?</Typography>
        <Typography className={classes.answer}>The core leadership team will be wearing hi-vis green neckers and all other volunteers will be wearing hi-vis purple neckers. Any questions or emergencies on the day, please approach any member of the team who will be able to assist you. Unit leaders will also be given details of the Be Prepared hotline which you can ring at any point during the day if you are not on site.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>Are you on social media?</Typography>
        <Typography className={classes.answer}>Yes, we are on <Link href='https://www.facebook.com/bepreparedevent.avon' className={classes.link}>Facebook</Link> and <Link href='https://www.instagram.com/bepreparedevent.avon' className={classes.link}>Instagram</Link>. You can follow us there to find out more about the event, as well as to be the first to hear any news about future events.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>Are consent forms required?</Typography>
        <Typography className={classes.answer}>Consent forms are required as per your organisation rules:</Typography>
        <Typography className={classes.answer}><span className={classes.important}>Scouts</span>: Leaders should ensure that they have parental consent via their normal means for participants to attend (this could be verbal, via an online signup, etc). There is no requirement to provide a paper consent form.</Typography>
        <Typography className={classes.answer}><span className={classes.important}>Guides</span>: Leaders are required to provide consent forms for all participants under the age of 18, which should be handed in to the event organisers on arrival.</Typography>
        <Typography className={classes.answer}>All leaders are responsible for ensuring they have emergency contact and medical details for their members, as well as setting up their own home contact/in-touch system. Please have your home contact information available to hand in to the event organisers when you arrive.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>Can my Young Leaders/Explorers/Rangers come?</Typography>
        <Typography className={classes.answer}>Yes, they can come as part of your leadership team, or they are more than welcome to <CustomLink linkText='sign up as an event volunteer' linkLocation={`/${this.state.location}/volunteer`} /> - please note that under 18s are not able to stay the night.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>What will I have to do on the day, as a Unit Leader?</Typography>
        <Typography className={classes.answer}>Unit Leaders will be asked to assist our volunteers on each of the bases. Our volunteers will be in charge of running the base but you may be asked to fetch drinks for the volunteers, help keep track of scores on some of the more complex bases, or simply encourage the participants. You will also be expected to supervise your Young People during the breaks and at lunch time, to give our volunteers a chance to sit down.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>What time does the event finish?</Typography>
        <Typography className={classes.answer}>The event finishes at approximately 16:00. Please ensure that all your members are off site by 16:30 so that we can get tidied away/ready for the next day.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>Can my unit camp overnight on site before heading home?</Typography>
        <Typography className={classes.answer}>No, camping on site is for our volunteers only. There are some other campsites nearby that you could stay at if you want to make a weekend of it:</Typography>
        <List dense>
          {campsites.map(site => (
            <ListItem className={classes.listItem} key={site.name}>
              <ListItemIcon className={classes.listItemIcon}><RoomIcon/></ListItemIcon>
              <ListItemText
                primary={
                  <Link
                    href={site.link}
                    className={classes.link}
                    target='_blank'
                    rel="noopener noreferrer"
                    onClick={() => this.registerCampsiteClick(site.name)}
                  >
                    {site.name}
                  </Link>
                }
                secondary={site.description}
              />
            </ListItem>
          ))}
        </List>

        <Typography variant='h5' component='h2' className={classes.question}>What are all these What Three Words addresses I keep seeing on your website?</Typography>
        <Typography className={classes.answer}>What Three Words divides the world into 3 metre squares and gives each square a unique combination of three words. We have used What Three Words because it is the easiest way to share exact locations. You can click on any What Three Words address on our website to see the location on a map, or you can download the app onto your phone to navigate directly to a What Three Words address.</Typography>
        <Typography className={classes.answer}>Find out more at <Link href='https://what3words.com/about' className={classes.link}>https://what3words.com/about</Link>.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>What happens if the event has to be cancelled?</Typography>
        <Typography className={classes.answer}>If the event has to be cancelled due to weather, then we will be able to claim on our insurance and will be able to refund all groups who were affected by the cancellation (it may be that one day is able to go ahead and the other is cancelled, in this case only those planning to attend the cancelled day will be entitled to a refund).</Typography>
        <Typography className={classes.answer}>Please note that this insurance cover <span className={classes.important}>does not</span> cover cancellation due to COVID (e.g. new national lockdown/participant illness).</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>Can we have a copy of the Be Prepared Risk Assessment?</Typography>
        <Typography className={classes.answer}>You can download a copy of the risk assessment here: <Link href={RiskAssessment} download={'Be Prepared Avon Risk Assessment'} className={classes.link} onClick={this.registerRAClick}>Be Prepared Avon Risk Assessment</Link>.</Typography>

        <Typography variant='h5' component='h2' className={classes.question}>What should I do if I spot an issue with the website?</Typography>
        <Typography className={classes.answer}>If you spot anything wrong with the website, please email us at <Link href='mailto:bepreparedevent.avon+website@gmail.com' className={classes.link}>bepreparedevent.avon+website@gmail.com</Link>.</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(AvonFaqs);
