import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { getAllPagesInOrder } from '../../utils/enums/Pages';
import { capitaliseWord } from '../../utils/capitaliseWord';
import locations from '../../utils/enums/locations';
import { getEventSocialMedia } from '../../utils/locationSpecificInfo';

const styles = (theme) => ({
  divider: {
    marginTop: '20px',
    height: '2px',
  },
  socialMediaWrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  facebookIcon: {
    color: theme.palette.action.disabled,
    '&:hover': {
      color: '#4267B2',
    },
    '&:focus-visible': {
      color: '#4267B2',
    },
    margin: '0 5px',
    cursor: 'pointer',
  },
  instagramIcon: {
    color: theme.palette.action.disabled,
    '&:hover': {
      color: '#c13584',
    },
    '&:focus-visible': {
      color: '#c13584',
    },
    margin: '0 5px',
    cursor: 'pointer',
  },
  emailIcon: {
    color: theme.palette.action.disabled,
    '&:hover': {
      color: theme.palette.primary.main,
    },
    '&:focus-visible': {
      color: theme.palette.primary.main,
      outline: `3px solid ${theme.palette.primary.main}`,
    },
    margin: '0 5px',
    cursor: 'pointer',
  },
  linkWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '15px',
  },
  footerLink: {
    margin: '0px 15px 3px',
    [theme.breakpoints.down('sm')]: {
      margin: '5px 10px 3px',
    },
  },
  boldText: {
    fontWeight: 700,
  },
});

class FooterLinks extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      currentPath,
      location: routes[1],
      footerPages: getAllPagesInOrder(true, routes[1], true),
    };
    this.isCurrentPage = this.isCurrentPage.bind(this);
    this.clickLocationBasedLink = this.clickLocationBasedLink.bind(this);
    this.clickLink = this.clickLink.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  componentDidUpdate() {
    const newPath = this.props.location.pathname;
    const routes = newPath.split('/');
    if (newPath !== this.state.currentPath) {
      this.setState({
        currentPath: newPath,
        location: routes[1],
        footerPages: getAllPagesInOrder(true, routes[1], true),
      });
    }
  }

  isCurrentPage(pageRoute) {
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    if (pageRoute === '') {
      return this.props.location.pathname === `/${routes[1]}`;
    }
    if (pageRoute === '/') {
      return this.props.location.pathname === '/';
    }
    return this.props.location.pathname.includes(`/${routes[1]}${pageRoute}`);
  }

  clickLocationBasedLink(url) {
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    if (url === '/' || url == '/past-events') {
      this.props.history.push(url);
    } else {
      this.props.history.push(`/${routes[1]}${url}`);
    }
  }

  clickLink(url) {
    this.props.history.push(url);
  }

  registerClickToSocialMedia(type) {
    ReactGA.event({
      category: 'Social Media',
      action: 'View profile',
      label: type,
    });
  }

  keyDown(e, url, socialMediaClick, locationBased) {
    if (e.charCode === 13) {
      if (socialMediaClick) this.registerClickToSocialMedia(`Mail Be Prepared ${capitaliseWord(this.state.location)}`);
      if (locationBased) this.clickLocationBasedLink(url);
      if (!locationBased) this.clickLink(url);
    }
  }

  render() {
    const { classes } = this.props;
    const socialMediaLinks = getEventSocialMedia(this.state.location);
    return (
      <>
        <Divider className={classes.divider}/>
        {locations.includes(this.state.location) ? (
          <div className={classes.socialMediaWrapper}>
            <div>
              <Link
                href={socialMediaLinks.facebook}
                aria-label={`go to be prepared ${this.state.location} facebook`}
                target='_blank'
                rel="noopener noreferrer"
                className={classes.facebookIcon}
                onClick={() => this.registerClickToSocialMedia(`Be Prepared ${capitaliseWord(this.state.location)} Facebook`)}
              >
                <FacebookIcon/>
              </Link>
              <Link
                href={socialMediaLinks.instagram}
                aria-label={`go to be prepared ${this.state.location} instagram`}
                target='_blank'
                rel="noopener noreferrer"
                className={classes.instagramIcon}
                onClick={() => this.registerClickToSocialMedia(`Be Prepared ${capitaliseWord(this.state.location)} Instagram`)}
              >
                <InstagramIcon/>
              </Link>
              <MailOutlineIcon
                className={classes.emailIcon}
                tabIndex={0}
                onClick={() => {this.registerClickToSocialMedia(`Mail Be Prepared ${capitaliseWord(this.state.location)}`); this.clickLocationBasedLink('/contact');}}
                onKeyPress={(e) => this.keyDown(e, '/contact', true, true)}
                aria-label={`contact be prepared ${this.state.location}`}
              />
            </div>
            <div className={classes.linkWrapper}>
              {this.state.footerPages.map(page => (
                <div key={page.pageName} className={classes.footerLink}>
                  {this.isCurrentPage(page.clickDestination) ?
                    <Typography color="primary" className={classes.boldText}>
                      {page.pageName}
                    </Typography> :
                    <Typography>
                      <Link
                        color='textSecondary'
                        onClick={() => this.clickLocationBasedLink(page.clickDestination)}
                        onKeyPress={(e) => this.keyDown(e, page.clickDestination, false, true)}
                      >
                        {page.pageName}
                      </Link>
                    </Typography>
                  }
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div className={classes.linkWrapper}>
            <div className={classes.footerLink}>
              {this.props.location.pathname === '/' ?
                <Typography color="primary" className={classes.boldText}>
                  Home
                </Typography> :
                <Typography>
                  <Link
                    color='textSecondary'
                    onClick={() => this.clickLink('/')}
                    onKeyPress={(e) => this.keyDown(e, '/', false, false)}
                  >
                    Home
                  </Link>
                </Typography>
              }
            </div>
            <div className={classes.footerLink}>
              <Typography>
                <Link
                  color='textSecondary'
                  onClick={() => this.clickLink('/avon')}
                  onKeyPress={(e) => this.keyDown(e, '/avon', false, false)}
                >
                  Be Prepared Avon
                </Link>
              </Typography>
            </div>
            <div className={classes.footerLink}>
              <Typography>
                <Link
                  color='textSecondary'
                  onClick={() => this.clickLink('/london')}
                  onKeyPress={(e) => this.keyDown(e, '/london', false, false)}
                >
                  Be Prepared London
                </Link>
              </Typography>
            </div>
            <div className={classes.footerLink}>
              {this.props.location.pathname === '/past-events' ?
                <Typography color="primary" className={classes.boldText}>
                  Past Events
                </Typography> :
                <Typography>
                  <Link
                    color='textSecondary'
                    onClick={() => this.clickLink('/past-events')}
                    onKeyPress={(e) => this.keyDown(e, '/past-events', false, false)}
                  >
                    Past Events
                  </Link>
                </Typography>
              }
            </div>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(withStyles(styles)(FooterLinks));
