import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Link } from '@material-ui/core';

const styles = () => ({
  link: {
    fontWeight: 700,
  },
});

class CustomLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      linkText: this.props.linkText,
      linkLocation: this.props.linkLocation,
    };
    this.clickLink = this.clickLink.bind(this);
    this.keyDown = this.keyDown.bind(this);
  }

  clickLink() {
    this.props.history.push(this.state.linkLocation);
  }

  keyDown(e) {
    if (e.charCode === 13) {
      this.clickLink();
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <Link
        onClick={this.clickLink}
        onKeyPress={this.keyDown}
        className={classes.link}
      >
        {this.state.linkText}
      </Link>
    );
  }
}

export default withRouter(withStyles(styles)(CustomLink));
