const avonVolunteers = [
  {
    year: '2022',
    volunteers: [
      'James Mitchell',
      'Ian Pearse',
      'Margaret',
    ],
  }, {
    year: '2023',
    volunteers: [
      'Margaret',
      'Victoria Hilton',
      'Russell',
      'Emily Morris',
      'Harry Solomon',
      'Rhiannon Hagger',
      'James Mitchell',
      'Ian Pearse',
      'Leia Baines',
      'Colin White',
      'Thea Moyle',
      'Jessica',
      'Louisa Lloyd',
    ],
  },
];

const londonVolunteers = [
  {
    year: '2022',
    volunteers: [
      'Karen',
      'Eleanor',
      'James Mitchell',
      'Debbie Clarke',
      'Karen Tricker',
      'Fiona',
      'Sue Leonard',
      'Tina Wheeler',
      'Jonathan',
      'Katy',
      'Hannah Dowling',
      'Margaret',
    ],
  }, {
    year: '2023',
    volunteers: [
      'James Mitchell',
      'Fiona Butterworth',
      'Karen Jordan',
      'Lesley Parsons',
      'Phil Atkins',
      'Charlotte Brewer',
      'Hannah Dowling',
      'Isobel Richardson',
      'Victoria Hilton',
      'Eleanor',
      'Leia Baines',
      'Colin White',
      'Jonathan',
      'James',
      'Debbie Clarke',
      'Margaret',
    ],
  },
];

// orders the years with most recent first
export const getYears = (location) => {
  switch (location) {
    case 'london':
      return londonVolunteers.map(year => year.year).sort().reverse();
    case 'avon':
      return avonVolunteers.map(year => year.year).sort().reverse();
    default:
      return [];
  }
};

// returns volunteers for a given year, sorted alphabetically
// will fail if you ask for a year which doesn't exist
export const getVolunteersForYear = (location, year) => {
  switch (location) {
    case 'london':
      return londonVolunteers.filter(y => y.year === year)[0].volunteers.sort();
    case 'avon':
      return avonVolunteers.filter(y => y.year === year)[0].volunteers.sort();
    default:
      return [];
  }
};
