import React from 'react';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, IconButton, ImageList, ImageListItem, lighten, Link, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = (theme) => ({
  container: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  imageList: {
    overflow: 'visible',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: '#9e9e9e',
  },
  inlineImageStyle: {
    maxWidth: '75%',
    maxHeight: '590px',
  },
  imageAndButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    maxWidth: '100%',
    marginBottom: '1em',
  },
  arrowButtons: {
    [theme.breakpoints.down('xs')]: {
      paddingInline: '0px',
    },
  },
  link: {
    fontWeight: 700,
    wordBreak: 'break-all',
  },
  image: {
    cursor: 'pointer',
    '&:focus-visible, &:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.5),
      zIndex: 2,
      boxShadow: `0 0 10px 10px ${lighten(theme.palette.primary.main, 0.5)}`,
      transform: 'scale(1.1)',
    },
  },
  viewMoreText: {
    textAlign: 'center',
    paddingBottom: '1em',
    paddingInline: '20px',
  },
});

class PastEventPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: this.props.photos,
      loadedPhotos: new Array(this.props.photos.length),
      googleDriveLink: this.props.googleDriveLink,
      imgOpen: false,
      viewingImg: 0,
    };
    this.clickImage = this.clickImage.bind(this);
    this.setPhotoLoaded = this.setPhotoLoaded.bind(this);
    this.keyDown = this.keyDown.bind(this);
    this.clickNext = this.clickNext.bind(this);
    this.clickPrevious = this.clickPrevious.bind(this);
    this.closeImg = this.closeImg.bind(this);
  }

  clickImage(imageNumber) {
    this.setState({
      imgOpen: true,
      viewingImg: imageNumber,
    });
  }

  setPhotoLoaded(imageNumber) {
    const loadedPhotos = this.state.loadedPhotos;
    loadedPhotos[imageNumber] = true;
    this.setState({
      loadedPhotos,
    });
  }

  keyDown(e, imageNumber) {
    if (e.charCode === 13) {
      this.clickImage(imageNumber);
    }
  }

  clickNext() {
    const currentPosition = this.state.viewingImg;
    let newPosition;
    if (currentPosition === this.props.photos.length - 1) {
      newPosition = 0;
    } else {
      newPosition = currentPosition + 1;
    }
    this.setState({
      viewingImg: newPosition,
    });
  }

  clickPrevious() {
    const currentPosition = this.state.viewingImg;
    let newPosition;
    if (currentPosition === 0) {
      newPosition = this.state.photos.length - 1;
    } else {
      newPosition = currentPosition - 1;
    }
    this.setState({
      viewingImg: newPosition,
    });
  }

  closeImg() {
    this.setState({
      imgOpen: false,
    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <ImageList rowHeight={160} cols={3} className={classes.imageList}>
          {this.state.photos.map((item, index) => (
            <ImageListItem key={item.img} cols={1} tabIndex={0} onKeyPress={(e) => this.keyDown(e, index)} classes={{ root: classes.image }}>
              <img
                src={item.img}
                alt={item.alt}
                onClick={() => this.clickImage(index)}
                onLoad={() => this.setPhotoLoaded(index)}
              />
              {!this.state.loadedPhotos[index] && <Skeleton variant="rect" height='100%' />}
            </ImageListItem>
          ))}
        </ImageList>
        <Dialog onClose={this.closeImg} open={this.state.imgOpen}>
          <DialogTitle>
            <Typography variant='h5' className={classes.dialogTitle}>{this.state.photos[this.state.viewingImg].title}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={this.closeImg}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <div className={classes.imageAndButtons}>
              <IconButton onClick={this.clickPrevious} className={classes.arrowButtons}>
                <NavigateBeforeIcon fontSize='large' />
              </IconButton>
              <img
                src={this.state.photos[this.state.viewingImg].img}
                alt={this.state.photos[this.state.viewingImg].alt}
                className={classes.inlineImageStyle}
              />
              <IconButton onClick={this.clickNext} className={classes.arrowButtons}>
                <NavigateNextIcon fontSize='large' />
              </IconButton>
            </div>
            <div className={classes.viewMoreText}>
              <Typography>View more photos at</Typography>
              <Typography><Link className={classes.link} href={this.state.googleDriveLink} target='_blank' rel="noopener noreferrer">{this.state.googleDriveLink}</Link></Typography>
            </div>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(PastEventPhotos));
