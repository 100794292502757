export const getContactFormLink = (location) => {
  switch (location) {
    case 'london':
      return 'https://formspree.io/f/xpzkokqz';
    case 'avon':
      return 'https://formspree.io/f/mlezypgj';
    default:
      return '';
  }
};

export const getEmailAddress = (location) => {
  switch (location) {
    case 'london':
      return 'beprepared.london@gmail.com';
    case 'avon':
      return 'bepreparedevent.avon@gmail.com';
    default:
      return '';
  }
};

export const getEventSocialMedia = (location) => {
  switch (location) {
    case 'london':
      return {
        facebook: 'https://www.facebook.com/beprepared.london',
        instagram: 'https://www.instagram.com/beprepared.london',
      };
    case 'avon':
      return {
        facebook: 'https://www.facebook.com/bepreparedevent.avon',
        instagram: 'https://www.instagram.com/bepreparedevent.avon',
      };
    default:
      return '';
  }
};

export const getVolunteersFacebookGroup = (location) => {
  switch (location) {
    case 'london':
      return 'https://www.facebook.com/groups/beprepared.london.volunteers';
    case 'avon':
      return 'https://www.facebook.com/groups/beprepared.avon.volunteers';
    default:
      return '';
  }
};

export const getVolunteersSignUpForm = (location) => {
  switch (location) {
    case 'london':
      return {
        shortLink: 'https://forms.gle/A4yq2ToQaHpgqwCJ6',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLSf5RGR7aAOIZyyKAJZ14bq1HzgI0S0oEvbXHLVdhAezwbYkPQ/viewform?embedded=true',
      };
    case 'avon':
      return {
        shortLink: 'https://forms.gle/e62rXcDsSwqXaJ5j7',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLSf93mDACFVUWDYADlTY0lEwqEh3jiHkOPhm58CkCNqX9m-DTg/viewform?embedded=true',
      };
    default:
      return {
        shortLink: '',
        longLink: '',
      };
  }
};

export const getEventSignUpForm = (location) => {
  switch (location) {
    case 'london':
      return {
        shortLink: 'https://forms.gle/w4vgd4oVqhGp8wp86',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLScfCMlOvwuqOzLrRqb6McLhrP40MVyMqdIAl_-qvO_nfFvrHw/viewform?embedded=true',
      };
    case 'avon':
      return {
        shortLink: 'https://forms.gle/EaxZ1Xu2dvc4tmiu8',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLSeZUD5xD6Czk_m_zFHahSLTGilSYVyxdFJS5V53QrPVeQxSeQ/viewform?embedded=true',
      };
    default:
      return {
        shortLink: '',
        longLink: '',
      };
  }
};

export const getEventBookingNotificationForm = (location) => {
  switch (location) {
    case 'london':
      return {
        shortLink: 'https://forms.gle/iW69c593KnMZeRFx6',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLSf7ZjzbWi2KAwkF4iLp_dflAPMbRWvF2RsKfy-tF8MMsxC81g/viewform?embedded=true',
      };
    case 'avon':
      return {
        shortLink: 'https://forms.gle/E4nyT7vzyWyPNJNeA',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLSdi60IkArDopJe_cEgoM8aSwhAntjuTJWEb5gy7jl0NAtg4rQ/viewform?embedded=true',
      };
    default:
      return {
        shortLink: '',
        longLink: '',
      };
  }
};

export const getEventFeedbackFormParticipants = (location) => {
  switch (location) {
    case 'london':
      return {
        shortLink: 'https://forms.gle/C2NYjgFeQjpkHa7D6',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLSfoz_onA6trSJRz7l9VqPWsY7swEg_a1Ew0G7fkKa6M2TZzpQ/viewform?embedded=true',
      };
    case 'avon':
      return {
        shortLink: 'https://forms.gle/rw6JySVag67QcGMc8',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLSf0SyQcaGFCE_V31eVKsVWbfAawdHnuchaIba8FfP_1e4J4zw/viewform?embedded=true',
      };
    default:
      return {
        shortLink: '',
        longLink: '',
      };
  }
};

export const getEventFeedbackFormLeaders = (location) => {
  switch (location) {
    case 'london':
      return {
        shortLink: 'https://forms.gle/HgJAL7CqouGi9pWN6',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLScrTm_WJ8-wxudcL6Ww_6A8xL5HuOCqPnPymWG4L3F50KCiJQ/viewform?embedded=true',
      };
    case 'avon':
      return {
        shortLink: 'https://forms.gle/oWTEJwhVhMkoQ2fGA',
        longLink: 'https://docs.google.com/forms/d/e/1FAIpQLScOGwTO_eqqeXmtKHxIB-7dbt2emL8Zs9KiqGvv3GL9Bb2fVA/viewform?embedded=true',
      };
    default:
      return {
        shortLink: '',
        longLink: '',
      };
  }
};

export const getEventDates = (location) => {
  switch (location) {
    case 'london':
      return {
        saturday: {
          section: 'scouts',
          // date: new Date('April 1, 2023'),
        },
        sunday: {
          section: 'guides',
          // date: new Date('April 2, 2023'),
        },
      };
    case 'avon':
      return {
        saturday: {
          section: 'scouts',
          // date: new Date('October 7, 2023'),
        },
        sunday: {
          section: 'guides',
          // date: new Date('October 8, 2023'),
        },
      };
    default:
      return {
        saturday: {
          section: 'scouts',
          date: null,
        },
        sunday: {
          section: 'guides',
          date: null,
        },
      };
  }
};

export const getEventTimetable = (location) => {
  switch (location) {
    case 'london':
      return [
        {
          time: '08:45',
          activity: 'Registration begins',
        }, {
          time: '09:15',
          activity: 'Opening ceremony',
        }, {
          time: '10:00',
          activity: 'Activities',
        }, {
          time: '10:25',
          activity: 'Break',
        }, {
          time: '10:50',
          activity: 'Activities',
        }, {
          time: '12:05',
          activity: 'Lunch (participants to bring a packed lunch)',
        }, {
          time: '13:05',
          activity: 'Activities',
        }, {
          time: '14:20',
          activity: 'Break',
        }, {
          time: '14:45',
          activity: 'Activities',
        }, {
          time: '15:45',
          activity: 'Closing ceremony & prizes',
        }, {
          time: '16:00',
          activity: 'Departure',
        }, {
          time: '16:30',
          activity: 'Site closes',
        },
      ];
    case 'avon':
      return [
        {
          time: '08:45',
          activity: 'Registration begins',
        }, {
          time: '09:15',
          activity: 'Opening ceremony',
        }, {
          time: '10:00',
          activity: 'Activities',
        }, {
          time: '10:25',
          activity: 'Break',
        }, {
          time: '10:50',
          activity: 'Activities',
        }, {
          time: '12:05',
          activity: 'Lunch (participants to bring a packed lunch)',
        }, {
          time: '13:05',
          activity: 'Activities',
        }, {
          time: '14:20',
          activity: 'Break',
        }, {
          time: '14:45',
          activity: 'Activities',
        }, {
          time: '15:45',
          activity: 'Closing ceremony & prizes',
        }, {
          time: '16:00',
          activity: 'Departure',
        }, {
          time: '16:30',
          activity: 'Site closes',
        },
      ];
    default:
      return [];
  }
};

const sortThanks = (a, b) => {
  return isFinite(a.person[0]) - isFinite(b.person[0])
    || a.person.localeCompare(b.person, undefined, { numeric: true, sensitivity: 'base' });
  // return a.person < b.person ? -1 : 1;
};

export const getThanks = (location) => {
  switch (location) {
    case 'london':
      return [
        {
          person: 'Amy Spencer',
          message: 'Amy founded Be Prepared in 2010 in Bath and ran the event up until 2019. We are grateful that she agreed to us taking over the event and moving it across the country.',
        }, {
          person: 'Girlguiding Greater London Kent County',
          message: 'Thanks to GLK for the loan of their Resusci Annies, and various other small items of kit for the 2022 and 2023 events, as well as their help in setting up a new event in the County.',
        }, {
          person: 'Crofton Oak Scout Troop',
          message: 'Many thanks to Crofton Oak Scouts for the loan of their trailer, and projector, as well as several smaller pieces of kit, for the 2022 event.',
        }, {
          person: 'Crofton Oak Explorers',
          message: 'Thanks to the Crofton Oak Explorer Scouts for helping us to sort out the suitcase activity during one of their meetings before the 2022 event.',
        }, {
          person: '5th Petts Wood Scouts',
          message: 'Many thanks to 5th Petts Wood Scouts for the hire of their minibuses for the 2022 event, to allow us to run a shuttle service for the girls, and for the 2023 event, to allow us to pick up a couple of units from the train station.',
        }, {
          person: 'Friends of Crofton Oak',
          message: 'Many thanks to Friends of Crofton Oak for the loan of their mess tent for the 2022 event.',
        }, {
          person: 'Bath District Scouts',
          message: 'Thanks to Bath District Scouts for the loan of some tents, and various smaller pieces of kit, for the 2022 event.',
        }, {
          person: '2nd Bath Boys Brigade',
          message: 'Thanks to 2nd Bath Boys Brigade for the loan of their Resusci Annies for the 2022 and 2023 events.',
        }, {
          person: 'BAM Nuttall',
          message: 'Thanks to BAM Nuttall for the loan of the K\'NEX from their \'Bridges 4 Schools\' activity for the 2023 event.',
        },
      ].sort(sortThanks);
    case 'avon':
      return [
        {
          person: 'Amy Spencer',
          message: 'Amy founded Be Prepared in 2010 in Bath and ran the event up until 2019. We are grateful that she agreed to us taking over the event.',
        }, {
          person: 'Crofton Oak Scout Troop',
          message: 'Many thanks to Crofton Oak Scouts for the loan of their projector, as well as several smaller pieces of kit, for the 2022 event.',
        }, {
          person: 'Bath District Scouts',
          message: 'Thanks to Bath District Scouts for the loan of some tents, and various smaller pieces of kit, for the 2022 event.',
        }, {
          person: '2nd Bath Boys Brigade',
          message: 'Thanks to 2nd Bath Boys Brigade for the loan of their Resusci Annies for the 2022 event.',
        }, {
          person: 'Bromley District Scouts',
          message: 'Thanks to Bromley District Scouts for the loan of their Resusci Annies for the 2022 event.',
        },
      ].sort(sortThanks);
    default:
      return [];
  }
};
