/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Link, Typography } from '@material-ui/core';
import { getVolunteersFacebookGroup, getVolunteersSignUpForm } from '../utils/locationSpecificInfo';
import { capitaliseWord } from '../utils/capitaliseWord';

const styles = () => ({
  justifyText: {
    textAlign: 'justify',
  },
  underline: {
    textDecoration: 'underline',
  },
  newWindowLink: {
    textAlign: 'right',
    margin: '2em 0 0.5em',
  },
  link: {
    fontWeight: 700,
  },
  breakAnywhere: {
    wordBreak: 'break-all', // link text is really long with no spaces and so caused overflow problems on mobile
  },
});

class Volunteer extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      location: routes[1],
    };
    this.registerFacebookLinkClicked = this.registerFacebookLinkClicked.bind(this);
    this.registerOpenedInNewTab = this.registerOpenedInNewTab.bind(this);
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Volunteer`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/volunteer` });
  }

  registerFacebookLinkClicked() {
    ReactGA.event({
      category: 'Social Media',
      action: 'View group',
      label: `${capitaliseWord(this.state.location)} Volunteers Facebook Group`,
    });
  }

  registerOpenedInNewTab() {
    ReactGA.event({
      category: 'Forms',
      action: 'Open in new tab',
      label: `${capitaliseWord(this.state.location)} Volunteer`,
    });
  }

  render() {
    const { classes } = this.props;
    const faceBookGroupLink = getVolunteersFacebookGroup(this.state.location);
    const volunteerSignUpForm = getVolunteersSignUpForm(this.state.location);
    return (
      <div>
        <Typography variant="h4">Volunteer</Typography>
        <Typography className={classes.justifyText}>We couldn&apos;t run Be Prepared without our team of volunteers. On the day, there are many jobs which you can pick from. We need people to run the bases (we plan the bases, and you&apos;ll pick one for each day), be traffic marshals, help out in the kitchen, and much more!</Typography>
        <Typography className={classes.justifyText}>There is no cost to being a volunteer, and you can sign up for as much or as little of the weekend as you are able to commit to. Volunteers will be able to stay overnight on site on Friday and Saturday night (indoors, over 18s only), and will be fed all meals. Rangers, Explorers, and Young Leaders are welcome to sign up as volunteers but please note you will be unable to stay on site overnight.</Typography>
        {/* <Typography className={classes.justifyText}>We will run a volunteers day in the New Year (date TBC) to meet each other, test all the bases, and assign various jobs. This is an optional day but is always useful, especially if you have never attended a Be Prepared event before.</Typography> */}
        {this.state.location === 'london' && (
          // <Typography className={classes.justifyText}>Volunteers sign up will open when booking opens - come back to this page to fill in the form when it does. We also have a Facebook group for volunteers - please request to join here: <Link href={faceBookGroupLink} target='_blank' rel="noopener noreferrer" className={`${classes.link} ${classes.breakAnywhere}`} onClick={this.registerFacebookLinkClicked}>{faceBookGroupLink}</Link>. All information on the Facebook group will be emailed out to those not in the group.</Typography>
          <Typography className={classes.justifyText}>If you are interested in becoming a Be Prepared volunteer, please fill out the following Google form. We also have a Facebook group for volunteers - please request to join here: <Link href={faceBookGroupLink} target='_blank' rel="noopener noreferrer" className={`${classes.link} ${classes.breakAnywhere}`} onClick={this.registerFacebookLinkClicked}>{faceBookGroupLink}</Link>. All information on the Facebook group will be emailed out to those not in the group.</Typography>
        )}
        {this.state.location === 'avon' && (
          <Typography className={classes.justifyText}>If you are interested in becoming a Be Prepared volunteer, please fill out the following Google form. We also have a Facebook group for volunteers - please request to join here: <Link href={faceBookGroupLink} target='_blank' rel="noopener noreferrer" className={`${classes.link} ${classes.breakAnywhere}`} onClick={this.registerFacebookLinkClicked}>{faceBookGroupLink}</Link>. All information on the Facebook group will be emailed out to those not in the group.</Typography>
        )}
        <Typography className={classes.justifyText}>If you are bringing any Guides or Scouts, please <span className={classes.underline}>do not</span> sign up to be a volunteer for that day - unit leaders will have different responsibilities during the day. You are more than welcome to sign up for the other day though!</Typography>
        <Grid container justify="center">
          <Grid item xs={12} sm={8} md={6}>
            <Typography className={classes.newWindowLink}><Link href={volunteerSignUpForm.shortLink} target='_blank' rel="noopener noreferrer" className={classes.link} onClick={this.registerOpenedInNewTab}>Open form in new window</Link></Typography>
            <iframe src={volunteerSignUpForm.longLink} width="100%" height="500" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
          </Grid>
        </Grid>
        <div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Volunteer);
