import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { Button, Grid, Typography } from '@material-ui/core';
import GuideLogo from '../images/logos/sections/guide-logo.jpg';
import ScoutLogo from '../images/logos/sections/scout-logo.png';
import CustomLink from '../components/CustomLink';
import { capitaliseWord } from '../utils/capitaliseWord';
import { getEventDates, getEventTimetable } from '../utils/locationSpecificInfo';

const styles = (theme) => ({
  header: {
    marginBottom: '1em',
    textAlign: 'center',
  },
  surroundingBorder: {
    textAlign: 'center',
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    padding: '30px',
    paddingTop: '15px',
  },
  guideSectionLogo: {
    height: '80px',
    margin: '1em',
    [theme.breakpoints.down('md')]: {
      height: '50px',
      margin: '0.5em',
    },
  },
  scoutSectionLogo: {
    height: '100px',
    margin: '1em',
    [theme.breakpoints.down('md')]: {
      height: '70px',
      margin: '0.5em',
    },
  },
  underlineText: {
    textDecoration: 'underline',
    marginTop: '1em',
    marginBottom: '0.25em',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  timetable: {
    display: 'flex',
    justifyContent: 'start',
    marginLeft: '0%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '12%',
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: '10%',
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: '20%',
    },
  },
  price: {
    fontWeight: 700,
    fontSize: '20px',
  },
  time: {
    width: '5ch',
  },
  hyphenDivWidth: {
    width: '3ch',
  },
  activity: {
    textAlign: 'left',
  },
  spacingTop: {
    marginTop: '0.5em',
  },
  link: {
    fontWeight: 700,
  },
  breakAnywhere: {
    wordBreak: 'break-all', // link text is really long with no spaces and so caused overflow problems on mobile
  },
});

class EventDetails extends React.Component {
  constructor(props) {
    super(props);
    const currentPath = this.props.location.pathname;
    const routes = currentPath.split('/');
    this.state = {
      location: routes[1],
      copiedClicked: false,
    };
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)} Event Details`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}/event-details` });
  }

  copyTimetableToClipboard(timetable) {
    const textToCopy = timetable.map((item) => `${item.time} - ${item.activity}`).join('\n');
    navigator.clipboard.writeText(`Be Prepared ${capitaliseWord(this.state.location)} - Provisional Timetable\n\n${textToCopy}`);
    ReactGA.event({
      category: 'Timetable',
      action: 'Copy Timetable to Clipboard',
      label: `Be Prepared ${capitaliseWord(this.state.location)} Timetable`,
    });
    this.setState({
      copiedClicked: true,
    });
  }

  render() {
    const { classes } = this.props;
    const dates = getEventDates(this.state.location);
    const timetable = getEventTimetable(this.state.location);

    return (
      <div>
        <Typography variant="h4" component='h1' className={classes.header}>{`Be Prepared ${capitaliseWord(this.state.location)}'s next event is`}</Typography>
        <Grid container justify="center" spacing={2}>
          <Grid item xs={12} sm={8} md={7} lg={5}>
            <div className={classes.surroundingBorder}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <img src={GuideLogo} alt='Guides' className={classes.guideSectionLogo} />
                <img src={ScoutLogo} alt='Scouts' className={classes.scoutSectionLogo} />
              </div>
              {dates.saturday.date ? (
                <>
                  <Typography variant='h5' component='body'>
                    {moment(dates.saturday.date).format('dddd Do MMMM')} ({capitaliseWord(dates.saturday.section)}) &amp;
                  </Typography>
                  <Typography variant='h5' component='body'>
                    {moment(dates.sunday.date).format('dddd Do MMMM YYYY')} ({capitaliseWord(dates.sunday.section)})
                  </Typography>
                </>
              ) : (
                <Typography variant='h5' component='body'>
                  Date TBC
                </Typography>
              )}
              <Typography variant='h6' className={classes.underlineText}>Cost</Typography>
              <Typography className={classes.price}>£15/Participant</Typography>
              <Typography>Leaders go free (but will be requested to assist with activities)</Typography>
              <Typography variant='h6' className={classes.underlineText}>Details</Typography>
              {/* eslint-disable-next-line max-len */}
              <Typography>When they arrive, your Scouts/Guides will be split into family groups. The families will compete against each other on various bases, all of which are about surviving the aftermath of an earthquake.</Typography>
              {/* eslint-disable-next-line max-len */}
              <Typography>They will learn first aid, rescue casualties, pioneer devices to put out fires, build a water pipeline, build rafts and much more.</Typography>
              {/* eslint-disable-next-line max-len */}
              <Typography>See the <CustomLink linkText='past events page' linkLocation="/past-events" /> for a sneak peek into some of the activities!</Typography>
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', gap: '2em' }}>
                <Typography variant='h6' className={classes.underlineText}>Provisional Timetable</Typography>
                {dates.saturday.date && (
                  <Button
                    variant='outlined'
                    color='primary'
                    size='small'
                    style={{ marginTop: '15px' }}
                    onClick={() => this.copyTimetableToClipboard(timetable)}
                  >
                    Copy Timetable
                  </Button>
                )}
                {this.state.copiedClicked && <Typography style={{ marginTop: '1em', marginLeft: '-1em' }} variant='body'>Copied to clipboard</Typography>}
              </div>
              <div>
                {dates.saturday.date ? timetable.map(entry => (
                  <div key={`${entry.time}`} className={classes.timetable}>
                    <Typography className={classes.time}>{entry.time}</Typography>
                    <Typography className={classes.hyphenDivWidth}>-</Typography>
                    <Typography className={classes.activity}>{entry.activity}</Typography>
                  </div>
                )) : <Typography>Timetable TBC</Typography>}
              </div>
              {dates.saturday.date && (
                <Typography variant='h4' component='body' className={classes.spacingTop}>
                  <CustomLink linkText='Book now!' linkLocation={`/${this.state.location}/sign-up`} />
                </Typography>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(EventDetails);
