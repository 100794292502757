/* eslint-disable max-len */

import React from 'react';
import ReactGA from 'react-ga4';
import { withStyles } from '@material-ui/core/styles';
import { Link, Grid, Typography } from '@material-ui/core';
import CustomLink from '../../components/CustomLink';
import Homepage1 from '../../images/events/2022_avon/homepage_1.jpg';
import Homepage2 from '../../images/events/2022_avon/homepage_2.jpg';
import Homepage3 from '../../images/events/2022_avon/homepage_3.jpg';
// import FlipCountdown from '@rumess/react-flip-countdown';
import '../../utils/countdownStyles.css';
import { capitaliseWord } from '../../utils/capitaliseWord';
import { getEventSocialMedia } from '../../utils/locationSpecificInfo';

const styles = (theme) => ({
  headline: {
    border: `5px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    textAlign: 'center',
    margin: '0 auto 1em',
    padding: '20px',
    maxWidth: '90vw',
  },
  bookingCountdownHeader: {
    textAlign: 'center',
  },
  justifyText: {
    textAlign: 'justify',
  },
  bookings: {
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: '10px',
    textAlign: 'center',
    margin: '1em auto',
    width: '60ch',
    maxWidth: '90vw',
  },
  facebookDiv: {
    maxWidth: '300px',
    [theme.breakpoints.up('lg')]: {
      float: 'right',
    },
  },
  spacingBottom: {
    marginBottom: '1em',
  },
  link: {
    fontWeight: 700,
  },
  image: {
    width: 'max(25%, 150px)',
    margin: 5,
  },
  imageDiv: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexGrow: 0,
    flexWrap: 'wrap',
    marginTop: '1em',
  },
});

class AvonHomepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      location: 'avon',
    };
    this.registerClickToFacebook = this.registerClickToFacebook.bind(this);
  }

  componentDidMount() {
    document.title = `Be Prepared ${capitaliseWord(this.state.location)}`;
    ReactGA.send({ hitType: 'pageview', page: `/${this.state.location}` });
    (function (d, s, id) {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        // eslint-disable-next-line no-undef
        FB.XFBML.parse();
        return;
      }
      const js = d.createElement(s); js.id = id;
      js.async = true;
      js.src = '//connect.facebook.net/en_GB/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));

    window.fbAsyncInit = function() {
      // eslint-disable-next-line no-undef
      FB.XFBML.parse();
    };
  }

  registerClickToFacebook() {
    ReactGA.event({
      category: 'Social Media',
      action: 'View profile',
      label: `Be Prepared ${capitaliseWord(this.state.location)} Facebook`,
    });
  }

  render() {
    const { classes } = this.props;
    // const isSmallScreen = window.innerWidth < 575; // hacky way to resize the FlipCountdown for smaller screens

    const facebookLink = getEventSocialMedia(this.state.location).facebook;

    return (
      <div>
        <Grid container spacing={2} justify="center">
          <Grid item md={12} lg={9} className={classes.justifyText}>
            <Typography variant='h5' component='body' className={classes.headline}> Would your Scouts or Guides know how to cope if disaster struck? If mains water, electricity and gas were shut off? If the shops were closed? If they were separated from their friends, family, and everything familiar to them? During Be Prepared, that’s exactly what the participants will learn as they survive the aftermath of a simulated Earthquake</Typography>
            {/* <Typography variant='h4' component='h2' className={classes.bookingCountdownHeader}>Event starts in:</Typography>
            <FlipCountdown
              endAtZero
              endAt={'2023-10-07 09:00:00'} // Date/Time
              size={isSmallScreen ? 'small' : 'medium'}
              theme="light"
              hideYear
              monthTitle='Months'
              dayTitle='Days'
              hourTitle='Hours'
              minuteTitle='Minutes'
              secondTitle='Seconds'
            /> */}
            <Typography style={{ marginTop: '1em' }}>An Earthquake in the UK may be fairly unlikely, but many of the circumstances it produces could happen in any disaster, in the UK or overseas. Your young people will learn to work together as teams, develop their leadership and teamwork skills, and also tick off a few <CustomLink linkText='badgework requirements' linkLocation={`/${this.state.location}/badges`} /> along the way. And most of all, they’ll have fun!</Typography>
            <Typography>Originally hosted in Bath for 10 years, Be Prepared has returned after a short hiatus with a new leadership team. Hosted at <CustomLink linkText='Cleeve Hill Scout Campsite' linkLocation={`/${this.state.location}/location`} />, Be Prepared is open to any Scout and Guide Units from across the UK who would like to attend.</Typography>
            <Typography>Run entirely by a <CustomLink linkText='team of volunteers' linkLocation={`${this.state.location}/volunteer`} />, the event aims to provide young people with an opportunity to develop and improve their resilience as well as practising their teamwork, leadership and decision making skills and learning some basic first aid. It’s also a great opportunity for the participants to have a fun day out and make some new friends.</Typography>
            <div className={classes.imageDiv}>
              <img src={Homepage2} alt='guides working together to put up a small red and black tent' className={classes.image} />
              <img src={Homepage1} alt='scouts practicing CPR on Resusci Annie dolls' className={classes.image} />
              <img src={Homepage3} alt='scouts carrying stretchers though a framework of rope and tape' className={classes.image} />
            </div>
            {/* <Typography variant='h6' component='body' className={classes.bookings}>Bookings can be made through the <CustomLink linkText='Sign Up page' linkLocation={`/${this.state.location}/sign-up`} /></Typography> */}
            <Typography variant='h6' component='body' className={classes.bookings}><CustomLink linkText='Sign up' linkLocation={`/${this.state.location}/sign-up`} /> to hear details of the next event</Typography>
          </Grid>
          <Grid item md={12} lg={3}>
            <div
              className={`fb-page ${classes.facebookDiv}`}
              data-href={facebookLink}
              data-tabs="timeline"
              data-width="300"
              data-height=""
              data-small-header="true"
              data-adapt-container-width="true"
              data-hide-cover="false"
              data-show-facepile="true"
            >
              <blockquote
                cite={facebookLink}
                className="fb-xfbml-parse-ignore"
                // can't use className here, otherwise facebook doesn't load at all
                style={{ border: '3px solid #4d2177', borderRadius: '10px', padding: '30px', margin: '0 auto', height: '65vh', textAlign: 'center' }}
              >
                <Typography variant='h6' component='body' className={classes.spacingBottom}><Link className={classes.link} href={facebookLink}>Be Prepared Avon Facebook</Link></Typography>
                <Typography variant='body2'>If you can&apos;t see our Facbook page here, please try refreshing the page, or <Link className={classes.link} href={facebookLink} onClick={this.registerClickToFacebook}>click here to view it on Facebook</Link></Typography>
              </blockquote>
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AvonHomepage);
