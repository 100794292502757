import React from 'react';
import HomeIcon from '@material-ui/icons/Home';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import PeopleIcon from '@material-ui/icons/People';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import PinDropIcon from '@material-ui/icons/PinDrop';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import PostAddIcon from '@material-ui/icons/PostAdd';
import AddLocationOutlinedIcon from '@material-ui/icons/AddLocationOutlined';
import PhotoCameraOutlinedIcon from '@material-ui/icons/PhotoCameraOutlined';

const pages = {
  LONDON_HOME: {
    clickDestination: '',
    tooltipText: 'be prepared london',
    pageName: 'Be Prepared London',
    icon: <HomeIcon />,
  },
  AVON_HOME: {
    clickDestination: '',
    tooltipText: 'be prepared avon',
    pageName: 'Be Prepared Avon',
    icon: <HomeIcon />,
  },
  EVENT_DETAILS: {
    clickDestination: '/event-details',
    tooltipText: 'event details',
    pageName: 'Event Details',
    icon: <InfoOutlinedIcon />,
  },
  LOCATION: {
    clickDestination: '/location',
    tooltipText: 'location',
    pageName: 'Location',
    icon: <PinDropIcon />,
  },
  TEAM: {
    clickDestination: '/team',
    tooltipText: 'team',
    pageName: 'The Team',
    icon: <PeopleIcon />,
  },
  SIGN_UP: {
    clickDestination: '/sign-up',
    tooltipText: 'sign up',
    pageName: 'Sign Up',
    icon: <PostAddIcon />,
  },
  BADGES: {
    clickDestination: '/badges',
    tooltipText: 'Related Badges',
    pageName: 'Related Badges',
    icon: <RadioButtonUncheckedIcon />,
  },
  VOLUNTEER: {
    clickDestination: '/volunteer',
    tooltipText: 'volunteer',
    pageName: 'Volunteer',
    icon: <EmojiPeopleIcon />,
  },
  FAQ: {
    clickDestination: '/faq',
    tooltipText: 'faq',
    pageName: 'FAQs',
    icon: <LiveHelpOutlinedIcon />,
  },
  CONTACT: {
    clickDestination: '/contact',
    tooltipText: 'contact us',
    pageName: 'Contact Us',
    icon: <EmailOutlinedIcon />,
  },
  OTHER_LOCATIONS: {
    clickDestination: '/',
    tooltipText: 'other locations',
    pageName: 'Other Locations',
    icon: <AddLocationOutlinedIcon />,
  },
  PAST_EVENTS: {
    clickDestination: '/past-events',
    tooltipText: 'past events',
    pageName: 'Past Events',
    icon: <PhotoCameraOutlinedIcon />,
  },
};

export const getAllPagesInOrder = (includeHomePage, homepageToInclude, includeOtherLocationLinks) => {
  let requiredPages = [];
  if (includeHomePage && homepageToInclude === 'london') requiredPages.push(pages.LONDON_HOME);
  if (includeHomePage && homepageToInclude === 'avon') requiredPages.push(pages.AVON_HOME);
  requiredPages.push(...[
    pages.EVENT_DETAILS,
    pages.LOCATION,
    pages.SIGN_UP,
    pages.TEAM,
    pages.VOLUNTEER,
    pages.BADGES,
    pages.FAQ,
  ]);
  if (includeOtherLocationLinks) requiredPages.push(...[
    pages.OTHER_LOCATIONS,
    pages.PAST_EVENTS,
  ]);
  requiredPages.push(pages.CONTACT);
  return requiredPages;
};
