import CasualtiesLondon2022 from '../images/events/2022_london/casualties.jpg';
import CPRLondon2022 from '../images/events/2022_london/cpr.jpg';
import FirefightingLondon2022 from '../images/events/2022_london/firefighting.jpg';
import InjuriesLondon2022 from '../images/events/2022_london/injuries.jpg';
import RaftBuildingLondon2022 from '../images/events/2022_london/raft_building.jpg';
import ReceptionCentreLondon2022 from '../images/events/2022_london/reception_centre.jpg';
import ShelterLondon2022 from '../images/events/2022_london/shelter.jpg';
import WaterPipeLondon2022 from '../images/events/2022_london/water_pipe.jpg';
import WaterPipelineLondon2022 from '../images/events/2022_london/water_pipeline.jpg';

import CasualtiesAvon2022 from '../images/events/2022_avon/casualties.jpg';
import CPRAvon2022 from '../images/events/2022_avon/cpr.jpg';
import FirefightingAvon2022 from '../images/events/2022_avon/firefighting.jpg';
import InjuriesAvon2022 from '../images/events/2022_avon/injuries.jpg';
import RaftBuildingAvon2022 from '../images/events/2022_avon/raft_building.jpg';
import ReceptionCentreAvon2022 from '../images/events/2022_avon/reception_centre.jpg';
import ShelterAvon2022 from '../images/events/2022_avon/shelter.jpg';
import WaterPipeAvon2022 from '../images/events/2022_avon/water_pipe.jpg';
import WaterPipelineAvon2022 from '../images/events/2022_avon/water_pipeline.jpg';

const imageDataLondon = {
  2022: [
    {
      img: CasualtiesLondon2022,
      title: 'Casualties Base',
      alt: 'Two girls carrying an old army stretcher with a plastic dummy, dressed in Scout uniform, on it, with a cup of rice balanced on its head',
    },
    {
      img: CPRLondon2022,
      title: 'CPR Base',
      alt: 'Two girls kneeling either side of a CPR dummy. One of the girls has her hands on the chest of the dummy, and is in the middle of performing chest compressions',
    },
    {
      img: FirefightingLondon2022,
      title: 'Firefighting Base',
      alt: 'Two groups of girls stand next to their homemade catapaults, facing towards a small fire with a wooden house outline in. One group is pulling back some elastic with a water balloon in, ready to fire it at the fire',
    },
    {
      img: InjuriesLondon2022,
      title: 'Injuries Base',
      alt: '',
    },
    {
      img: RaftBuildingLondon2022,
      title: 'Raft Building Base',
      alt: '',
    },
    {
      img: ReceptionCentreLondon2022,
      title: 'Reception Centre Base',
      alt: '',
    },
    {
      img: ShelterLondon2022,
      title: 'Shelter Base',
      alt: '',
    },
    {
      img: WaterPipeLondon2022,
      title: 'Water Base',
      alt: '',
    },
    {
      img: WaterPipelineLondon2022,
      title: 'Water Base',
      alt: '',
    },
  ],
};

const imageDataAvon = {
  2022: [
    {
      img: CasualtiesAvon2022,
      title: 'Casualties Base',
      alt: '',
    },
    {
      img: CPRAvon2022,
      title: 'CPR Base',
      alt: '',
    },
    {
      img: FirefightingAvon2022,
      title: 'Firefighting Base',
      alt: '',
    },
    {
      img: InjuriesAvon2022,
      title: 'Injuries Base',
      alt: '',
    },
    {
      img: RaftBuildingAvon2022,
      title: 'Raft Building Base',
      alt: '',
    },
    {
      img: ReceptionCentreAvon2022,
      title: 'Reception Centre Base',
      alt: '',
    },
    {
      img: ShelterAvon2022,
      title: 'Shelter Base',
      alt: '',
    },
    {
      img: WaterPipeAvon2022,
      title: 'Water Base',
      alt: '',
    },
    {
      img: WaterPipelineAvon2022,
      title: 'Water Base',
      alt: '',
    },
  ],
};

export const getImageData = (location, year) => {
  switch (location) {
    case 'london':
      return imageDataLondon[year];
    case 'avon':
      return imageDataAvon[year];
  }
};
